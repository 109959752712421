
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';


const Commands = (): null => {
  const {slug} = useParams()
  useEffect(() => {
    if (slug) {
      const [command, text] = slug.split(':')
      console.log('Received command: ' + command)
      console.log('Received text: ' + text)
    }
  }, [slug]);
  return null
}

export default Commands
