import {makeStyles, Theme, Typography} from '@material-ui/core';
import AddIcon from '../Icons/AddIcon';
import EditIcon from '../Icons/EditIcon';
import Spacer from '../Layout/Spacer';

const useStyles =
  makeStyles((theme: Theme) => ({
    root: {
      color: 'gray',
    },
  })
  )
export const SessionDates = ({createdAt, updatedAt}: {createdAt: string, updatedAt: string}) => {
  const classes = useStyles({})
  const formatted = (string: string) => {
    const date= new Date(string)
      return [date.toLocaleDateString(), date.toLocaleTimeString()].join(' ')
  }
  return <div className={classes.root}>
    <Typography >
      <EditIcon fontSize='small' /> {formatted(updatedAt)}
    </Typography>
    <Typography >
      <AddIcon fontSize='small'/> {formatted(createdAt)}
    </Typography>
    

  </div>}
