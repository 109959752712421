import {ApolloProvider} from '@apollo/client';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import {RecoilRoot} from 'recoil';
import {NotificationVariant} from '../../extension';
import {VSCode} from '../../global';
import client from './apollo';
import App from './App';
import reportWebVitals from './reportWebVitals';


declare global {
  interface Window {
    acquireVsCodeApi(): VSCode;
  }
}

export const vscode = window.acquireVsCodeApi?.() || {
  postMessage: (message: {command: string, text?: any, variant?: NotificationVariant}): void => {
    process.env.POST_MESSAGE_FROM_BROWSER && window.open(`vscode://sciple.sciple/${message.command}/${encodeURIComponent(message.text)}`, '_self')
  }
}

const hideReactNotLoadedMessage = () => {
  document.getElementById('reactNotLoadedMessage')?.setAttribute('style', 'display:none')
}
hideReactNotLoadedMessage()

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route path="/" default>
              <Suspense fallback='loading'>
                <App />
              </Suspense>
            </Route>
          </Switch>
        </Router>
      </ApolloProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
