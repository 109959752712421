import {gql, useQuery} from '@apollo/client'
import {ElementDefinition} from 'cytoscape'
import {useMemo} from 'react'
import {useRecoilValue} from 'recoil'
import {CLIENT} from '../../apollo'
import {labelsFilterAtom} from '../../atoms/labelsFilterAtom'
import {nodesLocalSelector} from '../../atoms/nodesAtom'
import {typesFilterAtom} from '../../atoms/typesFilterAtom'

export const GET_ELEMENTS = gql`
  query GetElements($ids: [String]!, $labels: [String]!, $types: [String]!) {
    GetElements(ids: $ids, labels: $labels, types: $types)
  }
`

const getLabel = ({title, name}, types) => title || name || types.toString()

export const useGetElements = () => {
  const labels = useRecoilValue(labelsFilterAtom)
  const types = useRecoilValue(typesFilterAtom)
  const _nodes = useRecoilValue(nodesLocalSelector)

  const nodes = useMemo(() => _nodes.reduce((acc, {id, x, y}) => ({...acc, [id]: {x, y}}), {}), [_nodes])

  const ids = useMemo(() => Object.keys(nodes), [nodes])

  const {data, loading, error} = useQuery(GET_ELEMENTS, {variables: {ids, labels, types}, context: {clientName: CLIENT.neo4j}})
  const entries = useMemo(() => JSON.parse(data?.GetElements || '[]'), [data?.GetElements])

  const navigator = {data: {id: 'navigator', label: '', types: ['navigator'], isNavigator: true}, classes: 'navigator'}
  //@ts-ignore  
  const elements = useMemo(() => nodes && data && entries?.flat().map(([data, types, source, target]) => {
    const {id} = data
    const navigatorEdge = source ? null : {data: {source: 'navigator', target: id, isNavigator: true, types: ['navigator']}, classes: 'navigator'}
    return [({
      classes: types,
      ...(source ? {} : {position: {x: nodes[id].x, y: nodes[data.id].y}}),
      data: {
        ...data,
        isElement: true,
        types,
        source,
        target,
        label: getLabel(data, source ? types.toLowerCase() : types).substr(0, 40)
      }
    }), navigatorEdge]
  }).flat().filter(e => e), [entries, nodes]) as ElementDefinition[]
  if (!data) {return {elements: null, loading, error}}
  return {elements: [navigator, ...elements], loading, error}
}
