import {AppBar, createStyles, makeStyles, Theme, Toolbar, Tooltip} from '@material-ui/core'
import {useSetRecoilState} from 'recoil'
import {sessionIdSelector} from '../../atoms/sessionIdAtom'
import Spacer from '../Layout/Spacer'
import scipleIcon from '../../assets/images/sciple-square-icon.png'
import ToggleArchivedSessions from './ToggleArchivedSessions'
import Logout from '../../Login/Logout'

const useStyles = makeStyles((theme: Theme) => createStyles({

  appBar: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(8),
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarImage: {
    margin: '20px',
    maxHeight: '35px',
  },
  clear: {paddingRight: theme.spacing(2)},
  icon: {
    height: theme.spacing(4),
    paddingRight: theme.spacing(1),
    width: theme.spacing(4),
  },
  toolbar: {
    color: 'blue',
    paddingRight: 24,
  },
}))

const SessionsAppBar = () => {
  const classes = useStyles({})
  const setSessionId = useSetRecoilState(sessionIdSelector)
  const showSessions = () => setSessionId('')

  return (
    <AppBar
      position='relative'
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Tooltip enterDelay={500} title='Show maps'>
          <img src={scipleIcon} className={classes.icon} onClick={showSessions} alt='Sciple Icon' />
        </Tooltip>
        <ToggleArchivedSessions />
        <Spacer />
        <Logout />
      </Toolbar>
    </AppBar>
  )
}

export default SessionsAppBar
