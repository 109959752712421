import {gql, useQuery} from '@apollo/client'
import {Card, CardHeader, Grid, Theme} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import {makeStyles} from '@material-ui/styles'
import {ReactElement} from 'react'
import {useRecoilValue} from 'recoil'
import {CLIENT} from '../../apollo'
import {currentUserIdState} from '../../atoms/cognitoUserAtom'
import {sessionFragment} from '../../atoms/nodesAtom'
import {showArchivedSessionsAtom} from '../../atoms/showArchivedSessionsAtom'
import DisplayError from '../Error/DisplayError'
import AddSessionCard from './AddSessionCard'
import SessionCard from './SessionCard'
import SessionsAppBar from './SessionsAppBar'

const useStyles = makeStyles(({ }: Theme) => ({
  container: {},
  item: {},
  root: {
    height: '100%',
    overflowY: 'scroll',
    width: '100%',
  },
}))

export const GET_USER_SESSION = gql`
  query getUser($id: ID!, $filter:ModelSessionFilterInput) {
    getUser(id: $id) {
      id
      sessions(filter: $filter) {
        items {
          ...Session
        }
      }
    }
  }
  ${sessionFragment}
`

type Props = {}

const Sessions = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const id = useRecoilValue(currentUserIdState)
  //todo Sort by recent

  const showArchivedSessions = useRecoilValue(showArchivedSessionsAtom)

  const {data, error, loading, networkStatus} = useQuery(GET_USER_SESSION, {
    context: {clientName: CLIENT.amplify},
    variables: {
      filter: {archived: {ne: !showArchivedSessions}},
      id
    }
  })

  if (loading) return <Skeleton variant='rect' width='100%' height='100%' />
  if (error) return <DisplayError message={error.message} statusCode={networkStatus} />

  const sessions = data.getUser.sessions.items

  const sessionsLimit = 5

  return (
    <>
      <SessionsAppBar />
      <Grid
        container
        spacing={2}
        className={classes.container}
      >
        {!showArchivedSessions && <Grid item xs={12} sm={6} md={4} lg={3} className={classes.item}>
          {sessions.length >= sessionsLimit ?
            <Card><CardHeader title={`Can't create more sessions, only ${sessionsLimit} sessions are allowed for basic accounts`} /></Card> :
            <AddSessionCard />
          }
        </Grid>}
        {sessions.map(({id}) =>
          <Grid item key={id} xs={12} sm={6} md={4} lg={3} className={classes.item}>
            <SessionCard id={id} />
          </Grid>,
        )}
      </Grid>
    </>
  )
}

export default Sessions
