import {Button, Card, CardActions, CardHeader, Dialog, IconButton, Theme, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ReactElement, useState} from 'react';
import {useRecoilState} from 'recoil';
import {nodesSelector} from '../../atoms/nodesAtom';
import ClearIcon from '../Icons/ClearIcon';
import Spacer from '../Layout/Spacer';

const useStyles = makeStyles(({palette}: Theme) => ({
  button: {color: palette.error.dark},
  header: {color: palette.error.dark}
}))

type Props = {}

const Clear = (props: Props): ReactElement => {
  const classes = useStyles({})
  const [ids, setIds] = useRecoilState(nodesSelector)
  const [open, setOpen] = useState(false);

  const onClick = () => setOpen(true)
  const onClickConfirm = () => {
    setOpen(false)
    setIds([])
  }
  const disabled = ids.length === 0

  return (
    <>
      <Tooltip enterDelay={0} title="Remove all enteties" aria-label="remove all entities">
        <IconButton className={classes.button} onClick={onClick} disabled={disabled}>
          <ClearIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open}>
        <Card>
          <CardHeader title='Remove all entities?' variant='subtitle1' className={classes.header} />
          <CardActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Spacer />
            <Button onClick={onClickConfirm} className={classes.button}>Confirm</Button>
          </CardActions>
        </Card>
      </Dialog>
    </>
  )
}

export default Clear
