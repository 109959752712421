import {IconButton, Theme, Tooltip} from '@material-ui/core'
import {ChevronLeft, Search} from '@material-ui/icons'
import {makeStyles} from '@material-ui/styles'
import {ReactElement} from 'react'
import {useRecoilState} from 'recoil'
import {searchDrawerOpenAtom} from '../../atoms/searchDrawerOpenAtom'

const useStyles = makeStyles(({ }: Theme) => ({
  root: {}
}))


type Props = {}

const ToggleSearchDrawer = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const [open, setOpen] = useRecoilState(searchDrawerOpenAtom)
  const onClick = () => setOpen(!open)

  return (
    <Tooltip enterDelay={500} title="Toggle search" aria-label="toggle search">
      <IconButton onClick={onClick}>
        <Search />
      </IconButton>
    </Tooltip>
  )
}

export default ToggleSearchDrawer
