import {Core, EventObject} from 'cytoscape';
import {useEffect} from 'react';
const useHighlightNeighborhood = (cy: Core) => {

  useEffect(() => {
    const handler = ({target}: EventObject) =>
      target.neighborhood().addClass('highlight')

    cy?.on('tapstart mouseover', 'node', handler)

    return () => {
      cy?.removeListener('tapstart mouseover', 'node', handler)
    }
  }, [cy])

  useEffect(() => {
    const handler = ({target}: EventObject) =>
      target.neighborhood().removeClass('highlight')

    cy?.on('tapend mouseout', 'node', handler)

    return () => {
      cy?.removeListener('tapend mouseout', 'node', handler)
    }
  }, [cy])
}

export default useHighlightNeighborhood
