import {Card, CardContent, Typography} from '@material-ui/core'
import { ReactElement } from 'react';

const example = (string: string): ReactElement => <><br /><span style={{color: 'gray'}}><code>{string}</code></span></>
const group = (descriptions: ReactElement[]): ReactElement => <><ul>{descriptions.map(v => <li>{v}</li>)}</ul><br /> </>

export const LuceneGuide = () => {
    return (
      <Card>
        <CardContent>
      {group([
<p>Use quotes to search for exact phrases: {example('"as an adjuvant"')}</p>,
<p>Spaces between words without AND/OR/NOT acts like AND + OR: {example('as an')}<br/>is the same as{example('(as AND an) OR (as OR an)')}</p>,
<p>OR matches any of the words or phrases: {example('"as an adjuvant" OR therapy')} </p>,
<p>AND matches all words or phrases: {example('"as an adjuvant" AND therapy')}</p>,
<p>NOT matches any words or phrases that do not match: {example('"as an adjuvant" NOT therapy')}</p>,
<p>( ) is a grouping operator, defining what belongs together: {example('who AND (is OR was)')}<br/>is different from {example('(who AND is) OR (was NOT here)')}</p>,
      ])}
    <Typography variant='body1'>
      Wildcards
          </Typography>
          {group([
<p>* matches all characters of any length: {example('*at')} {example('*at*')} {example('*at*adj*')} {example('at*')}</p>,
<p>? matches any single character: {example('?at')} {example('?at?')} {example('?a??t?')} {example('at?')}</p>,
<p>~ at the end of a word finds words that are similar: {example('who~')} Add a number after ~ to narrow/widen the search: {example('who~.8')}<br/>Phrases can use this as well: {example('"who is"~.8')}<br/>Lower numbers are more inclusive.<br/>0.1-1 works best, 0.5 is the default.</p>
        
          ])}
   
   <Typography variant='body1'>
      Other
    </Typography>
      {group([
      
<p>^ at the  end of a word boosts the word's or phrase's ranking: {example('which^4 is a')}{example('"which is"^10 a')}<br/>The default is 1. It can be any positive number.</p>
      ])}
    </CardContent>
              </Card>)
}
