import {AppBar, Button, Card, CardHeader, Checkbox, createStyles, Divider, Grid, makeStyles, TextField, Theme} from '@material-ui/core';
import {Auth, Hub} from 'aws-amplify';
import {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {vscode} from '..';
import {cognitoUserAtom} from '../atoms/cognitoUserAtom';
import {loggedInAtom} from '../atoms/loggedInAtom';
import {sessionIdSelector} from '../atoms/sessionIdAtom';
import GraphAppBar from '../components/AppBar/GraphAppBar';
import Loading from '../components/Loading/Loading';
import SessionsAppBar from '../components/Session/SessionsAppBar';
import SearchParams from '../helpers/searchParams';
import useListenToVscode from '../vscode/useListenToVscode';
import backgroundImage from './bg8.jpg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
  },
  pageHeader: {
    color: 'white',
    border: "0",
    height: "100vh",
    margin: "0",
    display: "flex!important",
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    alignItems: "center",
    background: "rgba(0,0,0, 0.5)",
    width: "100vw",
    left: "0",
    top: "0",
    content: '""'
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    maxWidth: "720px"
  },
  form: {
    margin: '0'
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
  },
  card: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%, -50%)',

  },
  cardTitle: {
    color: 'blue',
    textDecoration: "none",
    fontWeight: "bold",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto",

  },
  inputIconsColor: {color: 'blue'},
  description: {color: 'grey'},
  textCenter: {
    textAlign: "center"
  },
  button: {marginBottom: theme.spacing(2)}
}))

const validEmail = (email) => (/\S+@\S+\.\S+/).test(email)

const useListener = ({setError, setSignUp, email, setDidResend}) => {

  const setLoggedIn = useSetRecoilState(loggedInAtom)
  const setCognitoUser = useSetRecoilState(cognitoUserAtom)



  const resendVerificationCode = async () => {
    try {
      await Auth.resendSignUp(email)
      setDidResend(true)
    } catch (err) {
      setError(err)
    }
  }

  return ({payload: {data, event}}: {payload: {data?, event}}) => {
    const hasError = data?.name?.includes('Exception')
    setError(hasError ? data : null)
    const events = {
      UsernameExistsException: async () => {
        const {attributes: {email_verified}} = await Auth.currentUserInfo()
        //TODO use user is confirmed (update auth to allow read)
        email_verified ?
          setSignUp(false) :
          resendVerificationCode()
      },
      signIn_Failure: () => { },
      signUp_failure: () => { },
      signIn: async () => {
        setLoggedIn(true)
        setCognitoUser(await Auth.currentUserInfo())
      },
      signOut: () => {
        //* use window.location.reload() at the calling event
      },
      signUp: () => {
        data.name?.includes('Exception') && setError(data)
      },
      tokenRefresh_failure: () => {
        setError(null) // Do not inform user
      },
    }

    events[event]?.() || console.log('event not defined for ', event)
  }
}


const Login = () => {

  const classes = useStyles({})
  const [email, setEmail] = useState(process.env.REACT_APP_EMAIL || '');
  const [password, setPassword] = useState(process.env.REACT_APP_PASSWORD || '');
  const [error, setError] = useState(null);

  const [didResend, setDidResend] = useState(false);

  const onClickResend = async (evt) => {
    evt.preventDefault()
    await Auth.resendSignUp(email)
    setDidResend(true)
  }
  const [signUp, setSignUp] = useState(false);

  const beginSignUp = () => {
    setSignUp(true)
  }

  const listener = useListener({setError, setSignUp, email, setDidResend})
  useEffect(() => {
    Hub.listen('auth', listener)
    return () => {
      Hub.remove('auth', listener)
    }
  }, []);


  const onSignIn = async () => {
    try {
      const data = Auth.currentAuthenticatedUser()
      Hub.dispatch('auth', ({data, event: 'signIn'}))
    } catch (err) {
      setError(err)
    }
  }

  const onClickSignIn = async (evt?) => {
    evt?.preventDefault();
    try {
      await Auth.signIn(email, password)
      onSignIn()
    } catch (err) {
      setError(err)
    }
  }

const [checkedIfLoggedIn, setCheckedIfLoggedIn] = useState(false)

  const checkIfAuthenticated = async () => {
    const {authenticated} = await Auth.currentCredentials()
    authenticated && onSignIn()
    setCheckedIfLoggedIn(true)
  }

  useEffect(() => {checkIfAuthenticated()}, []);

  useListenToVscode({
    command: 'getConfig', onMessage:
      config => config && onClickSignIn()
  })

  useEffect(() => {
    vscode.postMessage({command: 'getConfig', text: 'autoLogin'})
  }, []);


  const [accepted, setChecked] = useState(false)
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verifyUser, setVerifyUser] = useState(false);

  const onClickAccept = () => setChecked(current => !current)

  const [invalidEmail, setInvalidEmail] = useState(false)
  useEffect(() => {
    invalidEmail && validEmail(email) && setInvalidEmail(false)
  }, [email, invalidEmail]);

  const onClickSignUp = async evt => {
    evt.preventDefault()
    if (!validEmail(email)) {
      setInvalidEmail(true)
    } else {
      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            name,
            profile: company,
          }
        })
        setVerifyUser(true)
      } catch (err) {
        setError(err)
      }
    }
  }

  const onClickVerify = async () => {
    try {
      await Auth.confirmSignUp(email, verificationCode)
      Hub.dispatch('auth', ({event: 'signIn'}))
      window.location.reload()
    } catch (err) {
      setError(err)
    }
  }

  const onClickLoginInstead = () => setSignUp(false)

  const infoFilledIn = name && email && company && password
  const disabled = !(accepted && infoFilledIn)
  


  return checkedIfLoggedIn ? <>
    <img style={{height: '100vh', width: '100vw', objectFit: 'cover', position: 'absolute', margin: '0'}} src={backgroundImage} />
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={4}>s
          <Card className={classes.card}>
            <form className={classes.form}>
              <CardHeader
                color="primary"
                className={classes.cardHeader}
                title={signUp ? 'Sign Up' : 'Login'}
              />
              <TextField
                className={classes.textField}
                fullWidth
                label={`${signUp ? '* ' : ''}Email`}
                type='email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={!validEmail(email)}
              />
              <TextField
                className={classes.textField}
                fullWidth
                label={`${signUp ? '* ' : ''}Password`}
                type='password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={password.length < 8}
              />
              {error && error.code !== "CodeMismatchException" && <h4 className={classes.description + " " + classes.textCenter}>
                {error.message}
              </h4>}
              {!signUp && <div className={classes.textCenter}>
                <Button color="primary" variant={'contained'} size={'large'} onClick={onClickSignIn} className={classes.button}>
                  Login
                </Button>
              </div>}
              {signUp ?
                <>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label='* Name'
                    type='name'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    error={name.length < 1}
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label='* Company'
                    type='company'
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    error={company.length < 1}
                  />
                  <div className={classes.textCenter}>
                    <Checkbox
                      checked={accepted}
                      onChange={onClickAccept}
                      name="checkedB"
                      color="primary"
                    />
                    I agree to the <a href='sciple.ai/terms'>terms and conditions</a>
                  </div>
                  {verifyUser && <>
                    <Divider />
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label='Verification Code'
                      type='code'
                      value={verificationCode}
                      onChange={(event) => setVerificationCode(event.target.value)}
                    />
                    {didResend && <h4 className={classes.description + " " + classes.textCenter}>
                      A new verification code is send
                    </h4>}
                    {error?.code == "CodeMismatchException" && <h4 className={classes.description + " " + classes.textCenter}>
                      {error.message}
                    </h4>

                    }
                    <div className={classes.textCenter}>
                      <Button variant='text' color="primary" size='small' className={classes.button} onClick={onClickResend}>
                        Resend
                      </Button>
                    </div>
                  </>
                  }
                </>
                : <h4 className={classes.description + " " + classes.textCenter}>
                  {`Don't have an account?`}
                </h4>
              }
              <div className={classes.textCenter}>
                {!signUp ?
                  <Button variant='text' color="secondary" size='small' className={classes.button} onClick={beginSignUp}>
                    Sign up
                  </Button>
                  :
                  !verifyUser ?
                    <Button variant='contained' color="primary" size='large' className={classes.button} onClick={onClickSignUp} disabled={disabled}>
                      {(accepted && infoFilledIn) ? 'Sign up' : (!accepted && infoFilledIn ? 'We need your agreement above' : 'Fill in the above to sign up')}
                    </Button>
                    :
                    <Button variant='contained' color="primary" size={'large'} className={classes.button} onClick={onClickVerify} disabled={verificationCode.length !== 6}>
                      {verificationCode.length === 6 ? 'Create account' : 'Enter the 6 digit code send by mail'}
                    </Button>
                }
              </div>
              {signUp && <div className={classes.textCenter}>
                <Button color="secondary" variant={'text'} size={'small'} onClick={onClickLoginInstead} className={classes.button}>
                  Login instead
                </Button>
              </div>}
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  </> :<AppBar />
}

export default Login
