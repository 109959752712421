import {FormControl, IconButton, Input, InputAdornment, InputLabel, Theme, Typography, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {ReactElement} from 'react'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {searchTextAtom} from '../../atoms/searchTextAtom'
import {drawerWidth} from '../Drawer/SearchDrawer'
import { ChevronLeft } from '@material-ui/icons';
import { feedbackDrawerOpenAtom } from '../../atoms/feedbackDrawerOpenAtom'

const useStyles = makeStyles(({spacing}: Theme) => ({
  root: { },
  form: {width: drawerWidth - spacing(2), marginLeft: spacing(2)},
  header: {
    width: '100%',
    textAlign: 'center',
  },
}))


type Props = {}

const FeedbackField = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const setFeedbackDrawerOpen = useSetRecoilState(feedbackDrawerOpenAtom)
  const close = () => setFeedbackDrawerOpen(false)

  return (
    <div className={classes.root}>
        <Button onClick={close} fullWidth>
        <div className={classes.header}>
          <Typography variant='h6'>Feedback</Typography>
        </div>
        <IconButton onClick={close}>
            <ChevronLeft />
        </IconButton>
        </Button>
    </div>
  )
}

export default FeedbackField
