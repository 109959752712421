
import { Button, Dialog, DialogActions, DialogContent, makeStyles, DialogTitle, TextField } from '@material-ui/core';
import Spacer from '../Layout/Spacer';

const useStyles = makeStyles(theme => ({
  root: {textAlign: 'center'},
}))

const AddSessionPrompt = ({open, onConfirm, onCancel, name, setName}) => {
  
  const classes = useStyles({});

  return (
    <div>
      <Dialog open={open} onClose={onCancel} className={classes.root}>
        <DialogTitle>Create Map</DialogTitle>
        
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Spacer/>
          <Button onClick={onConfirm}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddSessionPrompt;
