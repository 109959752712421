import Auth from '@aws-amplify/auth';
import {IconButton, Theme, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ReactElement} from 'react';
import SignOutIcon from '../components/Icons/SignOutIcon';

const useStyles = makeStyles(({ }: Theme) => ({
  root: {}
}))


type Props = {}

const Logout = (props: Props): ReactElement => {
  const _classes = useStyles({})
  const { } = props

  const onClick = async () => {
    await Auth.signOut({global: true})
    window.location.href = '/'
  }

  return (
    <Tooltip enterDelay={500} title="Logout" aria-label="logout">
      <IconButton onClick={onClick}>
        <SignOutIcon />
      </IconButton>
    </Tooltip>
  )
}

export default Logout
