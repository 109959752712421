import {ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, split} from "@apollo/client";
import Auth from '@aws-amplify/auth';
import {AUTH_TYPE, createAuthLink} from "aws-appsync-auth-link";
import {createSubscriptionHandshakeLink} from "aws-appsync-subscription-link";
import awsConfig from "./aws-exports";



const neo4jLink = new HttpLink({
  uri:
    process.env.REACT_APP_NEO4J_GRAPHQL_URI
})

const AmplifyHttpLink = new HttpLink({
  uri:
    awsConfig.aws_appsync_graphqlEndpoint,
});

export const CLIENT = {amplify: 'amplify', neo4j: 'neo4j'}

const amplifyLink = from([
  createAuthLink({
    url: awsConfig.aws_appsync_graphqlEndpoint,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
        return token
      },
    },
    region: awsConfig.aws_appsync_region,
  }),
  split(op => {
    const {operation} = op.query.definitions[0] as any;

    if (operation === 'subscription') {
      return false;
    }

    return true;
  }, AmplifyHttpLink, createSubscriptionHandshakeLink(
    {
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => {
          const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
          return token
        },
      },
      region: awsConfig.aws_appsync_region,
      url: awsConfig.aws_appsync_graphqlEndpoint,
    },
    AmplifyHttpLink
  ))
])

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:
    ApolloLink.split(

      operation => operation.getContext().clientName === CLIENT.amplify,
      amplifyLink, ApolloLink.split(
        operation => operation.getContext().clientName === CLIENT.neo4j, neo4jLink, ({operationName}) => {
          throw new Error(`clientName is not defined for ${operationName}`)
        }
      )
    )
  ,
});

export default client
