import {useEffect} from 'react';

const useListenToVscode = ({command, onMessage}: {command: string, onMessage: any}) => {
  useEffect(() => {
    const listener = ({data}) =>
      data.command === command && onMessage(data.message)

    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
    }
  }, []);
}

export default useListenToVscode
