import {ReactElement} from 'react'
import {Card, CardActionArea, CardActions, CardContent, CardHeader, IconButton, Theme, Tooltip} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {useRecoilState} from 'recoil'
import toggle from '../../helpers/toggle'
import {showArchivedSessionsAtom} from '../../atoms/showArchivedSessionsAtom'
import ArchiveIcon from '../Icons/ArchiveIcon';

const useStyles = makeStyles(({ }: Theme) => ({
  root: {},
  content: {height: '300px', width: '100%', display: 'flex'},
  icon: {height: '70px', width: '70px', display: 'block', margin: 'auto'},
}))


type Props = {}

const ToggleArchivedSessions = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const [show, set] = useRecoilState(showArchivedSessionsAtom)

  const onClick = () => set(toggle)

  return (
    <Tooltip enterDelay={500} title={`${show ? 'Hide' : 'Show'} archived maps`} aria-label={`${show ? 'Hide' : 'Show'} archived maps`} >
      <IconButton onClick={onClick}>
        <ArchiveIcon />
      </IconButton>
    </Tooltip>
  )
}

export default ToggleArchivedSessions
