import {FormControl, IconButton, Input, InputAdornment, InputLabel, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {ReactElement} from 'react'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {searchDrawerOpenAtom} from '../../atoms/searchDrawerOpenAtom'
import {searchTextAtom} from '../../atoms/searchTextAtom'
import {drawerWidth} from '../Drawer/SearchDrawer'
import { ChevronLeft } from '@material-ui/icons';

const useStyles = makeStyles(({spacing}: Theme) => ({
  root: {paddingTop: spacing(1), paddingBottom: spacing(-.2)},
  form: {width: drawerWidth - spacing(2), marginLeft: spacing(2)}
}))


type Props = {}

const SearchField = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const setSearchDrawerOpen = useSetRecoilState(searchDrawerOpenAtom)
  const [text, setText] = useRecoilState(searchTextAtom)
  const onChange = ({target: {value}}) => {
    setText(value)
  }
  const onFocus = () => setSearchDrawerOpen(true)
  const close = () => setSearchDrawerOpen(false)

  return (
    <div className={classes.root}>
      <FormControl fullWidth className={classes.form}>
        <InputLabel htmlFor="search-field">Search</InputLabel>
        
      <Input
        value={text}
        onChange={onChange}
        onFocus={onFocus}
        fullWidth
        endAdornment={
          <InputAdornment position="end">
               <IconButton onClick={close}>
      <ChevronLeft/>
      </IconButton>
          </InputAdornment>
        }
        
        />
        </FormControl>
   
    </div>
  )
}

export default SearchField
