// Button to show menu actions

import {List, ListItem} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import {useRecoilState, useRecoilValue} from 'recoil'
import { feedbackDrawerOpenAtom } from '../../atoms/feedbackDrawerOpenAtom'
import {menuOpenAtom} from '../../atoms/menuOpenAtom'
import toggle from '../../helpers/toggle'
import Logout from '../../Login/Logout'
import About from '../About/About'
import Feedback from '../Feedback/Feedback'
import MenuIcon from '../Icons/MenuIcon'
import ShowSessions from '../Session/ShowSessions'

const Menu = () => {
  const [open, setOpen] = useRecoilState(menuOpenAtom)

  return (
    <><IconButton aria-label='show menu' onClick={() => setOpen(toggle)}>
      <MenuIcon />
    </IconButton>
      <Drawer
        variant='temporary'
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}
      >
        <List>
          <ListItem>
            <Logout />
          </ListItem>
          <ListItem>
            <Feedback/>
          </ListItem>
          <ListItem>
            <About />
          </ListItem>
          <ListItem>
            <ShowSessions />
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}

export default Menu
