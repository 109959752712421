import {atom, selector} from 'recoil'

export type CognitoUser = {
  id: string, username: string, attributes: {
    email: string,
    email_verified: boolean
    phone_number: string
    phone_number_verified: boolean
    sub: string
  }
}

export const cognitoUserAtom = atom<CognitoUser | null>(
  {
    default: null,
    key: 'cognitoUserAtom'
  }
)


export const currentUserIdState = selector(({
  key: 'currentUserId',
  get: ({get}) => get(cognitoUserAtom).id,
  set: ({set, get}, id) => set(cognitoUserAtom, {...get(cognitoUserAtom), id})
}))
