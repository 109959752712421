import {IconButton, Theme, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ReactElement} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil'
import { feedbackDrawerOpenAtom } from '../../atoms/feedbackDrawerOpenAtom';
import { menuOpenAtom } from '../../atoms/menuOpenAtom';
import FeedbackIcon from '../Icons/FeedbackIcon';

const useStyles = makeStyles(({ }: Theme) => ({
  root: {}
}))


type Props = {}

const Feedback = (props: Props): ReactElement => {
  const _classes = useStyles({})
  const { } = props

  const setFeedbackDrawerOpen = useSetRecoilState(feedbackDrawerOpenAtom)
  const setMenuOpen = useSetRecoilState(menuOpenAtom)

  const onClick = () => {setFeedbackDrawerOpen(true); setMenuOpen(false)}

  return (
    <Tooltip enterDelay={500} title="Feedback" aria-label="logout">
      <IconButton onClick={onClick}>
        <FeedbackIcon />
      </IconButton>
    </Tooltip>
  )
}

export default Feedback