import {gql} from '@apollo/client'
import {atom, RecoilValueReadOnly, selector, selectorFamily, useRecoilValue} from 'recoil'
import client, {CLIENT} from '../apollo'
import {sessionIdSelector} from './sessionIdAtom'

export const nodeFragment = gql`
  fragment Node on Node {
      id
        x
        y
  }
`
export const sessionFragment = gql`
  fragment Session on Session {
    id
    name
    nodes {
      ...Node
    }
    labels
    types
    archived
    updatedAt
    createdAt
  }
  ${nodeFragment}
`
const query = gql`
  query GetSessionNodes($sessionId: ID!) {
    getSession(id: $sessionId) {
      ...Session
  }
}
  ${sessionFragment}
`

const mutation = gql`
  mutation UpdateNodes($sessionId: ID!, $nodes: [NodeInput]!) {
    updateSession(input: {id: $sessionId, nodes: $nodes} ) {
      ...Session
    }
  }
  ${sessionFragment}
`


let history: Node[][] = [[]]
const historyIndexAtom = atom({key: 'nodesHistoryIndex', default: 0})

export const useCanChangeHistory = (variant: 'undo' | 'redo') => {
  const index = useRecoilValue(historyIndexAtom)
  return variant === 'undo' ? index > 0 : index < history.length - 1
}

export const nodesHistory = selectorFamily({
  key: 'nodesHistory',
  get: () => () => { },
  set: (type: 'redo' | 'undo') => ({get, set}) => {
    const change = type === 'redo' ? 1 : -1
    const newIndex = get(historyIndexAtom) + change
    set(historyIndexAtom, newIndex)
    const nodes = history[newIndex]
     const sessionId = get(sessionIdSelector)
    const sync = async () => {
      try {
        await client.mutate({mutation, variables: {sessionId, nodes}, context: {clientName: CLIENT.amplify}})
      } catch (err) {
        throw new Error(err.message)
      }
    }
    sync()
  }
})

export type Node = {id: string, x: number, y: number}

export const nodesAtom = atom<Node[]>(
  {
    default: [],
    key: 'nodesAtom'
  }
)

export const nodesLocalSelector = selector<Node[]>({
  key: 'nodesLocalSelector',
  get: ({get}) => history[get(historyIndexAtom)],
  set: (_, nodes:Node[]) => {
    history = [nodes]
  },
})

export const nodesSelector = selector<Node[]>({
  key: 'nodesSelector',
  get: ({get}) => {
    return history[get(historyIndexAtom)]
  },
  set: ({get, set}, nodes: Node[]) => {
    
    const sessionId = get(sessionIdSelector)
    const sync = async () => {
      try {
        await client.mutate({mutation, variables: {sessionId, nodes}, context: {clientName: CLIENT.amplify}})
      } catch (err) {
        throw new Error(err.message)
      }
    }
    sync()
    history.push(nodes)
    set(historyIndexAtom, history.length - 1)
  },
})

