import {v4 as uuid} from 'uuid'
import {useMutation, useQuery} from '@apollo/client'
import {Card, CardActionArea, CardActions, CardHeader, TextField, Theme, Tooltip} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import Cytoscape from 'cytoscape'
import {ReactElement, useEffect, useMemo, useRef, useState} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import CytoscapeComponent from 'sciple-react-cytoscapejs'
import {CLIENT} from '../../apollo'
import {localStylesheetState} from '../../atoms/stylesheetState'
import {GET_ELEMENTS} from '../Graph/useGetElements'
import IconButton from '@material-ui/core/IconButton'
import DuplicateIcon from '../Icons/DuplicateIcon'
import {ARCHIVE_SESSION, CREATE_SESSION, DELETE_SESSION, GET_SESSION, RENAME_SESSION} from '../../amplifyApi/session'
import ArchiveIcon from '../Icons/ArchiveIcon'
import EditIcon from '../Icons/EditIcon'
import AcceptIcon from '../Icons/AcceptIcon'
import CancelIcon from '../Icons/CancelIcon'
import Spacer from '../Layout/Spacer'
import {currentUserIdState} from '../../atoms/cognitoUserAtom'
import {sessionIdSelector} from '../../atoms/sessionIdAtom'
import DeleteIcon from '../Icons/DeleteIcon'
import {showArchivedSessionsAtom} from '../../atoms/showArchivedSessionsAtom'
import {deleteSession} from '../../graphql/mutations'
import RestoreIcon from '../Icons/RestoreIcon'
import {GET_USER_SESSION} from './Sessions'
import {SessionDates} from './SessionDates'

const useStyles = makeStyles(({ }: Theme) => ({
  content: {
    height: '300px',
    width: '100%'
  },
  cytoscape: {
    height: '100%',
    width: '100%'
  },
  cytoscapeWrapper: {
    height: '100%',
    pointerEvents: 'none',
    width: '100%'
  },

  root: {},
}))

type Props = {id: string}

const SessionCard = (props: Props): ReactElement => {
  const classes = useStyles({})
  const {id} = props

  const session = useQuery(GET_SESSION, {
    context: {clientName: CLIENT.amplify},
    variables: {id}
  })

  const cyRef = useRef(null)
  const cy = cyRef.current
  const containerRef = useRef()

  const fallbackStylesheet = useRecoilValue(localStylesheetState)
  const {labels, types, name, stylesheet, createdAt, updatedAt } = session.data?.getSession || {
    labels: [],
    name: '',
    stylesheet: null,
    types: []
  }

  const nodeMap = useMemo(() => (session.data?.getSession.nodes || []).reduce((map, {id, x, y}) => map.set(id, {
    x,
    y
  }), new Map()), [session.data?.getSession.nodes])
  const ids = useMemo(() => Array.from(nodeMap.keys()), [nodeMap])

  const elements = useQuery(GET_ELEMENTS, {
    context: {clientName: CLIENT.neo4j},
    variables: {
      ids,
      labels,
      types
    }
  })

  const currentUserId = useRecoilValue(currentUserIdState)
  const refetchQueries = [
    {
      context: {clientName: CLIENT.amplify},
      query: GET_USER_SESSION,
      variables: {
        filter: {archived: {ne: true}},
        id: currentUserId
      }
    },
    {
      context: {clientName: CLIENT.amplify},
      query: GET_USER_SESSION,
      variables: {
        filter: {archived: {ne: false}},
        id: currentUserId
      }
    },
    {
      context: {clientName: CLIENT.neo4j},
      query: GET_ELEMENTS,
      variables: {
        ids,
        labels,
        types
      }
    }]
  const [duplicate] = useMutation(CREATE_SESSION, {
    context: {clientName: CLIENT.amplify},
    refetchQueries,
    variables: {
      session: {
        id: uuid(),
        labels,
        name: `${name} copy`,
        nodes: session.data?.getSession.nodes?.map(({__typename, ...rest}) => {
          return rest
        }) || [],
        types,
        userSessionsId: currentUserId,
      },
    },
  })

  const [editName, setEditName] = useState(false)
  const [newName, setNewName] = useState()
  const [rename] = useMutation(RENAME_SESSION, {
    context: {clientName: CLIENT.amplify},
    variables: {
      id,
      name: newName
    }
  })
  const [archive] = useMutation(ARCHIVE_SESSION, {
    context: {clientName: CLIENT.amplify},
    refetchQueries,
    variables: {
      archived: true,
      id
    }
  })
  const [restore] = useMutation(ARCHIVE_SESSION, {
    context: {clientName: CLIENT.amplify},
    refetchQueries,
    variables: {
      archived: false,
      id
    }
  })
  const [deleteSession] = useMutation(DELETE_SESSION, {
    context: {clientName: CLIENT.amplify},
    refetchQueries,
    variables: {session: {id}}
  })

  const showArchivedSessions = useRecoilValue(showArchivedSessionsAtom)

  const cyElements = JSON.parse(
    elements.data?.GetElements ||
    '[[]]').flat()
    .map(([{id}, types, source, target]) => {
      const {x, y} = nodeMap.get(id) || {
        x: undefined,
        y: undefined
      }

      return ({
        classes: types,
        data: source ? {
          source,
          target
        } : {id},
        position: {
          x,
          y
        }
      })
    })

  useEffect(() => {
    if (!cy) return

    // Fit on first load
    cy.fit()

    const handler = () => {
      cy.fit()
    }

    cy.on('viewport', handler)

    return () => {
      cy.removeAllListeners()
      cyRef.current = null
    }
  }, [cy, cyElements])

  const onChangeName = evt => setNewName(evt.target.value)

  const onClickAcceptNewName = async () => {
    try {
      await rename()
      setEditName(false)
    } catch (e) {
    }
  }
  const onClickCancelNewName = () => {
    setNewName(name)
    setEditName(false)
  }

  const setSessionId = useSetRecoilState(sessionIdSelector)

  const onClickSession = () => setSessionId(id)

  return (

    <Card className={classes.root}>
      {editName ?
        <>
          <TextField fullWidth value={newName || name} onChange={onChangeName} />
          <CardActions>
            <Tooltip enterDelay={500} title={'Cancel'}>
              <IconButton onClick={onClickCancelNewName} color='secondary'>
                <CancelIcon />
              </IconButton>
            </Tooltip>
            <Spacer />
            <Tooltip enterDelay={500} title={'Save name'}>
              <IconButton onClick={onClickAcceptNewName} color='primary'>
                <AcceptIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
        </> :
        <CardHeader title={name} />
      }
      <SessionDates createdAt={createdAt} updatedAt={updatedAt} />
      <CardActionArea ref={containerRef} className={classes.content} onClick={onClickSession}>
        <div className={classes.cytoscapeWrapper}>
          <CytoscapeComponent
            //@ts-ignore
            Cytoscape={Cytoscape}
            containerRef={containerRef}
            className={classes.cytoscape}
            cy={(cy) => cyRef.current = cy}
            stylesheet={stylesheet || fallbackStylesheet}
            elements={cyElements}
            maxZoom={1E+1}
            minZoom={1E-1}
          />
        </div>
      </CardActionArea>
      <CardActions>
        {showArchivedSessions ?
          <Tooltip enterDelay={500} title={'Delete map'}>
            <IconButton onClick={() => deleteSession()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          :
          <Tooltip enterDelay={500} title={'Archive map'}>
            <IconButton onClick={() => archive()}>
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        }
        <Spacer />
        <Tooltip enterDelay={500} title={'Rename map'}>
          <IconButton onClick={() => setEditName(true)} disabled={editName}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {showArchivedSessions ?
          <Tooltip enterDelay={500} title={'Restore map'}>
            <IconButton onClick={() => restore()}>
              <RestoreIcon />
            </IconButton>
          </Tooltip>
          :
          <Tooltip enterDelay={500} title={'Duplicate map'}>
            <IconButton onClick={() => duplicate()}>
              <DuplicateIcon />
            </IconButton>
          </Tooltip>}
      </CardActions>
    </Card>
  )
}

export default SessionCard
