import {IconButton, Tooltip} from '@material-ui/core'
import {ReactElement} from 'react'
import {useSetRecoilState} from 'recoil'
import {shouldLayoutAtom} from '../../atoms/shouldLayoutAtom'
import FitIcon from '../Icons/FitIcon'

const Fit = (): ReactElement => {

  const setShouldLayout = useSetRecoilState(shouldLayoutAtom)

  const onClick = () => setShouldLayout([false, {fit: true}])

  return (
    <Tooltip enterDelay={500} title="Fit view" aria-label="fit view">
      <IconButton onClick={onClick}>
        <FitIcon />
      </IconButton>
    </Tooltip>
  )
}

export default Fit
