import {gql} from '@apollo/client'
import {nodeFragment, sessionFragment} from '../atoms/nodesAtom'

// export const SessionFragment = gql`
//     fragment Session on Session {
//       id
//       name
//       stylesheet
//       ...Nodes
//       labels
//       types
//     }
//     ${nodeFragment}
//   `

export const ClientFragment = gql`
    fragment Client on Client {
      id
      name
      stylesheet
      nodes  {
      ...Node
      }
      labels
      types
      createdAt
      updatedAt
    }
    ${nodeFragment}
  `

export const GET_SESSION_nodes = gql`
  query GetSessionNodes($sessionId: ID!) {
    getSession(id: $sessionId) {
      ...Session
    }
  }
  ${sessionFragment}
`


export const GET_SESSION = gql`
  query GetSession($id: ID!) {
    getSession(id: $id) {
      ...Session
    }
  }
  ${sessionFragment}
`


export const UserFragment = gql`
    fragment User on User {
      id
      sessions {
        items {
          ...Session
        }
      }
      clients {
        items {
          ...Client
        }
      }
    }
    ${sessionFragment}
    ${ClientFragment}
  `

export const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      ...User
    }
  }
  ${UserFragment}
`


export const CREATE_USER = gql`
mutation CreateUser($id: ID!, $email: String!){
 createUser(input: {id: $id, email: $email}) {
    ...User
  }
}
${UserFragment}
${sessionFragment}
`

export const CREATE_USER_SESSION = gql`
mutation CreateUserSession($session:CreateSessionInput!){
  createSession(input: $session) {
    ...Session
  }
}
${sessionFragment}
`

export const CREATE_SESSION = gql`
mutation CreateSession($session:CreateSessionInput!){
  createSession(input: $session) {
    ...Session
  }
}
${sessionFragment}
`

export const DELETE_SESSION = gql`
mutation DeleteSession($session:DeleteSessionInput!){
  deleteSession(input: $session) {
    id
  }
}
`

export const RENAME_SESSION = gql`
mutation RenameSession($id:ID!, $name:String!){
  updateSession(input: {id: $id, name: $name}) {
    ...Session
  }
}
${sessionFragment}
`

export const ARCHIVE_SESSION = gql`
mutation ArchiveSession($id:ID!, $archived:Boolean){
  updateSession(input: {id: $id, archived: $archived}) {
    ...Session
  }
}
${sessionFragment}
`

export const UPDATE_STYLESHEET = gql`
  mutation UpdateStylesheet($sessionId: ID!, $stylesheet: String) {
    updateSession(input:{id: $sessionId, stylesheet:$stylesheet}) {
      ...Session
    }
  }
  ${sessionFragment}
`
