import {Core, EdgeSingular, EventObject, NodeSingular} from 'cytoscape';
import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {filterDrawerArticleExpandedAtom} from '../../atoms/filterDrawerArticleExpandedAtom';
import {useAppend} from '../../atoms/hooks';
import {searchHighlightsAtom} from '../../atoms/searchHighlightsAtom';
import {showAuthorsAtom} from '../../atoms/showAuthorsAtom';
import {elementColors} from '../../atoms/stylesheetState';
import {useGetArticle} from '../Article/useGetArticle';



const useOnTapNode = (cy: Core | null) => {

  const setFilterDrawerArticleExpanded = useSetRecoilState(filterDrawerArticleExpandedAtom)

  const setShowAuthors = useSetRecoilState(showAuthorsAtom)


  const appendSearchHighlight = useAppend(searchHighlightsAtom)


  const highlight = (node: NodeSingular) => {
    const {title, name, types} = node.data()
    const color = elementColors.nodes[types[0]]
    const searchString = (title || name) as string
    appendSearchHighlight({searchString, color})
  }

  const getArticle = useGetArticle()
  useEffect(() => {
    const onTap = async ({target}: EventObject): Promise<void> => {
      const node = target[0] as NodeSingular
      const id = node.id()
      const {types} = node.data()
      if (!types) return

      if (types.includes('Article')) {
        getArticle(id, {updateState: true})
        setFilterDrawerArticleExpanded(true)
      } else {
        highlight(node)
      }
    }

    cy?.on('tap', 'node', onTap)

    return () => {
      cy?.off('tap', 'node', onTap)
    }
  }, [cy]);

  useEffect(() => {
    const onTap = (): void => {
      setShowAuthors(true)
    }

    cy?.on('tap', 'edge.AUTHORED', onTap)
    cy?.on('tap', 'node.Person', onTap)
    return () => {
      cy?.off('tap', 'edge.AUTHORED', onTap)
      cy?.off('tap', 'node.Person', onTap)

    }
  }, [cy]);

  useEffect(() => {
    const onTap = (variant: 'AUTHORED' | 'MENTIONS') => ({target}: EventObject): void => {
      const edge = target[0] as EdgeSingular
      const [article, entity] = variant === 'MENTIONS' ? [edge.source(), edge.target()] : [edge.target(), edge.source()]
      highlight(entity)
      getArticle(article.id(), {updateState: true})
      setFilterDrawerArticleExpanded(true)
    }

    cy?.on('tap', 'edge.AUTHORED', onTap('AUTHORED'))
    cy?.on('tap', 'edge.MENTIONS', onTap('MENTIONS'))


    return () => {
      cy?.off('tap', 'edge.AUTHORED', onTap('AUTHORED'))
      cy?.off('tap', 'edge.MENTIONS', onTap('MENTIONS'))
    }
  }, [cy]);
}

export default useOnTapNode;

