import {Button, Theme} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import {ReactElement, useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {articleIdAtom} from '../../atoms/articleIdAtom';
import {searchHighlightsAtom} from '../../atoms/searchHighlightsAtom';
import {showAuthorsAtom} from '../../atoms/showAuthorsAtom';
import {drawerWidth} from '../Drawer/SearchDrawer';
import DisplayError from '../Error/DisplayError';
import Markdown from '../Markdown/Markdown';

const useStyles = makeStyles(({spacing}: Theme) => ({
  root: {height: '100%', margin: spacing(1), marginRight: spacing(2)},
  button: {width: '100%'},
  select: {padding: spacing(2), width: drawerWidth, textAlign: 'center'},
  loading: {width: drawerWidth},
}))

const getArticle = (text) => {
  if (!text) return ''

  const {Title, Abstract, Author, Published_date, href, Citations, Body, References} = JSON.parse(text) as {Title: string, Abstract: string, Published_date: string, href: string, Citations: string, Body: string, References: string, Author: {Name: string, Email: string, Institution: string}[]}

  const s = Author.length > 1 ? 's' : ''
  return `${Published_date || ''}

${Citations ? 'Citations ' + Citations : ''}

${href || ''}
### ${Title}

## Abstract
${Abstract}

### Author${s} and Affiliation${s}

  ${Author.map(({Name, Email, Institution}) => `${Name}, ${[Email, Institution].filter(x => x).join(', ')}`.replace(', <i></i>', '')).join('\n\n')}


${Body ?
      `## Article
${Body.replaceAll(`
`, `

`)}`
      : ''}


  ${References ?
      `### References
${References}`
      : ''}      `
}



type Props = {}

const Article = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const [text, setText] = useState<string | null>(null);

  const [loading, setLoading] = useState(true);
  const [networkStatus, setNetworkStatus] = useState(null);
  const [error, setError] = useState(null);
  const [showAuthors, setShowAuthors] = useRecoilState(showAuthorsAtom)


  const articleId = useRecoilValue(articleIdAtom)

  const fetch = async (articleId: string) => {
    console.log("fetch ~ articleId", articleId)
    try {
      const xhttp = new XMLHttpRequest()
      xhttp.addEventListener('load', async (request) => {
        //@ts-ignore 
        setText(decodeURIComponent(request.currentTarget.responseText))
        setLoading(false)
        setNetworkStatus(null)
        setError(null)
      })
      xhttp.open('GET', `${process.env.REACT_APP_NEO4J_GRAPHQL_URI.replace('graphql', 'document')}/?_id=${encodeURIComponent(articleId)}`)
      xhttp.send()
    } catch (err) {
      console.log("fetch ~ err", err)
      setError(err)
    }
  }

  useEffect(() => {
    articleId && fetch(articleId) && setLoading(true)
  }, [articleId]);

  const searchHighlights = useRecoilValue(searchHighlightsAtom)


  if (!articleId) return <h4 className={classes.select}>Select an article in the graph</h4>
  if (loading) return (
    <div className={classes.root}>

      <div className={classes.loading}>
        <Skeleton variant='text' width={400} height={25} />
        <Skeleton variant='text' width={400} height={25} />
        <Skeleton variant='text' width={400} height={25} />
        <Skeleton variant='text' width={100} height={40} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={25} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
        <Skeleton variant='text' width={370} height={20} />
        <Skeleton variant='text' width={400} height={20} />
      </div>
    </div>
  )
  if (error) return (
    <DisplayError
      error={error}
      statusCode={networkStatus}
      message={error?.message || `Article with articleId ${articleId} was not found`}
    />
  )

  const article = getArticle(text)

  const getAuthors = () => {
    const {Author} = JSON.parse(text) as {Author: {Name: string, Institution: string, Email: string, Corresponding: boolean}[]}
    const s = Author.length > 1 ? 's' : ''

    return `#### Author${s} and Affiliation${s}
    
${Author.map(({Name, Email, Institution}) => `${Name}, <i>${[Email, Institution].filter(x => x).join(', ')}</i>`.replace(', <i></i>', '')).join('\n\n')}
    }`
  }

  return (
    <div className={classes.root}>
      {/* <Button className={classes.button} onClick={() => setShowAuthors(current => !current)}>{showAuthors ? 'Hide' : 'Show'} authors and affiliations</Button>
      {showAuthors && <Markdown text={getAuthors()} searchHighlights={searchHighlights} />} */}
      <Markdown
        text={article}
        // highlights={JSON.parse(highlights)}
        searchHighlights={searchHighlights}
        refetchArticle={() => fetch(articleId)}
      //@ts-ignore 
      />
    </div>
  )
}

export default Article
