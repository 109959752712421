import {IconButton, Theme, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ReactElement} from 'react';
import AboutIcon from '../Icons/AboutIcon';
import { useSetRecoilState } from 'recoil';
import { menuOpenAtom } from '../../atoms/menuOpenAtom';

const useStyles = makeStyles(({ }: Theme) => ({
  root: {}
}))


type Props = {}

const About = (props: Props): ReactElement => {
  const _classes = useStyles({})
  const { } = props
  const setMenuOpen = useSetRecoilState(menuOpenAtom)

  const onClick = () => {setMenuOpen(false)}

  return (
    <Tooltip enterDelay={500} title="About" aria-label="logout">
      <IconButton onClick={onClick}>
        <AboutIcon />
      </IconButton>
    </Tooltip>
  )
}

export default About