/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:e4e940cd-a75e-4999-a39f-e47d19adc0fc",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_cqbsbSvON",
    "aws_user_pools_web_client_id": "21crilcm3br98l8hi6dmufsobe",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://hoepx3bdszfnvjikiyis45k5oi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fakeApiId123456",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "sendEmailApi",
            "endpoint": "https://pp20gzjw1l.execute-api.eu-west-1.amazonaws.com/canary",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
