import { ReactElement, useState } from "react"
import {makeStyles} from '@material-ui/styles'
import {useRecoilState, useSetRecoilState} from 'recoil'
import { FormControl, Input, InputLabel, TextField, Theme, Button} from '@material-ui/core'
import { API } from 'aws-amplify';
import { feedbackAtom } from "../../atoms/feedbackAtom";
import {drawerWidth} from '../Drawer/FeedbackDrawer'

type Props = {}

const useStyles = makeStyles(({spacing}: Theme) => ({
    root: {marginLeft: spacing(1),
        marginRight: spacing(1),
        marginBottom: spacing(2),
        textAlign: 'center',
    },
    form: {
        width: '90%',
        marginLeft: spacing(1),
        marginRight: spacing(1),
    },
    submitButton: {
        marginTop: spacing(2)
    }
  }))

const FeedbackForm = (props: Props): ReactElement => {
    const classes = useStyles({})
    const [feedback, setFeedback] = useRecoilState(feedbackAtom)
    const onChange = ({target: {value}}) => {
        setFeedback(value)
    }
    const onSubmit = (e) => {
        alert("The feedback was sent!")
        e.preventDefault()
        postData()
        setFeedback("");
    }
    const postData = async () => {
        //Take input from form data and package and send it to a AWS Lambda Rest endpoint
        // AWS Credentials
        const apiName = "sendEmailApi";
        const path = "/sendEmail-canary";
        const email = "noreply@sciple.ai";  /*TODO: replace with login name*/
        const name = "DevOps";
        console.log("Data: ");

        const data = await API.post(
        apiName,
        path,
        {
            body: {
              email: email,
              name: name,
              message: feedback,
            }
        },
        );
        // Error handling
        console.log("Data: " + data)
    }

    return (
        <div className={classes.root}>    
          <FormControl  className={classes.form}>
            <form onSubmit={onSubmit}>
              <InputLabel>Feedback</InputLabel>
              <Input
                value={feedback}
                onChange={onChange}   
                rows={"5"}
                multiline={true}
                fullWidth
                />
              <Button 
                type={"submit"}
                variant={"contained"}
                className={classes.submitButton}
                >
                    Send
              </Button>
            </form>
          </FormControl>
        </div>
    )
}

export default FeedbackForm