import {createMuiTheme} from '@material-ui/core'

const dark = createMuiTheme({
  palette: {
    type: 'dark',
  },
  spacing: 8
})


const light = createMuiTheme({
  palette: {
    type: 'light',
  },
  spacing: 8
})


const themes = {light, dark}

export default themes
