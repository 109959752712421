import {AppBar, createStyles, makeStyles, Theme, Toolbar, useTheme} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import Cytoscape, {EdgeDefinition, NodeDefinition, Stylesheet} from 'cytoscape';
import fcose from 'cytoscape-fcose';
import layoutUtilities from 'cytoscape-layout-utilities';
import { or } from 'ramda';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useMeasure, usePrevious} from 'react-use';
import {useRecoilValue} from 'recoil';
import CytoscapeComponent from 'sciple-react-cytoscapejs';
import {CyContext} from '../../App';
import { feedbackDrawerOpenAtom } from '../../atoms/feedbackDrawerOpenAtom';
import {filterDrawerArticleExpandedAtom} from '../../atoms/filterDrawerArticleExpandedAtom';
import {filterDrawerExpandedAtom} from '../../atoms/filterDrawerExpandedAtom';
import {searchDrawerOpenAtom} from '../../atoms/searchDrawerOpenAtom';
import {localStylesheetState} from '../../atoms/stylesheetState';
import {drawerWidth} from '../Drawer/SearchDrawer';
import useCxtmenu from './useCxtmenu';
import {useGetElements} from './useGetElements';
import useHighlightEdgesOnTapNode from './useHighlightNeighborhood';
import useNavigator from './useNavigator';
import useOnTapNode from './useOnTapNode';
import usePreFetchOnHover from './usePreFetchOnHover';
import useSyncPositions from './useSyncPositions';

Cytoscape.use(layoutUtilities)
Cytoscape.use(fcose)

const loaderSize = 300
const loaderSizeHalf = 300 / 2

const useStyles = makeStyles(({spacing}: Theme) => createStyles({
  cytoscape: {
    height: `calc(100vh - 64px)`,
    width: '100vw',
  },
  root: {
    height: `calc(100vh - 64px)`,
    width: '100vw',
  },
  actions: {
    position: 'fixed', zIndex: 2000, left: 0, top: 0, padding: spacing(2), pointerEvents: 'none'
  },
  clear: {
    position: 'fixed', zIndex: 2000, right: 0, top: 0, padding: spacing(2)
  },
  loader: {
    position: 'absolute',
    top: `calc(50vh - ${loaderSizeHalf}px)`,
    left: `calc(50vw - ${loaderSizeHalf}px)`,

    width: loaderSize, height: loaderSize
  }
}))

export const PADDING = 100

export const layout = {
  name: 'fcose',
  quality: 'proof',
  spacingFactor: 3,
  padding: PADDING,
  animate: true,
  stop: ({cy}) => {
    const nodes = cy.nodes()
    nodes.unlock()
    nodes.emit('free')
  },
  fit: false,
}



type Props = {stylesheet?: Stylesheet[]}

function Graph(props: Props) {
  const theme = useTheme()
  const classes = useStyles({})
  const { } = props
  const stylesheet = useRecoilValue(localStylesheetState)

  const containerRef = useRef()
  const cyRef = useContext(CyContext)
  const cy = cyRef.current
  const previousCy = usePrevious(cy)

  const [measureRef, {width, height}] = useMeasure()

  const {elements: latestElements, loading, error} = useGetElements()

  const [prevJson, setPrevJson] = useState(null);


  const searchDrawerOpen = useRecoilValue(searchDrawerOpenAtom)
  const filterDrawerExpanded = useRecoilValue(filterDrawerExpandedAtom)
  const filterDrawerArticleExpanded = useRecoilValue(filterDrawerArticleExpandedAtom)


  const prevElements = prevJson?.elements
  const fallback = useMemo(() => (prevElements?.nodes ? [...(prevElements.nodes || []), ...(prevElements.edges || [])] : []), [prevElements])
  const elements = latestElements
    || fallback

  useEffect(() => {
    setPrevJson(previousCy?.json() as {elements: {nodes: NodeDefinition[], edges: EdgeDefinition[]}})
  }, [loading]);


  useCxtmenu(cy)
  useOnTapNode(cy)
  useHighlightEdgesOnTapNode(cy)
  useNavigator(cy, {width, height})
  useSyncPositions(cy)
  usePreFetchOnHover(cy)

  useEffect(() => {
    cy?.emit('viewport')
  }, [searchDrawerOpen, filterDrawerExpanded, filterDrawerArticleExpanded, cy, elements]);

  if (!elements) return <Skeleton variant='circle' className={classes.loader} />
  if (error) return <p>{error.message}</p>

  return (
    <>
      <AppBar>
        <Toolbar />
      </AppBar>
      <div className={classes.root} ref={measureRef}
        style={{
          left: searchDrawerOpen ? drawerWidth : drawerWidth,
          width:
            `calc(100% - ${(filterDrawerExpanded ? drawerWidth + theme.spacing(0) : theme.spacing(8)) + (filterDrawerArticleExpanded ? drawerWidth + theme.spacing(4) : theme.spacing(8)) + ( searchDrawerOpen ? drawerWidth : 0)}px)`,
        }}
      >
        <CytoscapeComponent
          //@ts-ignore
          Cytoscape={Cytoscape}
          containerRef={containerRef}
          className={classes.cytoscape}
          cy={(cy) => cyRef.current = cy}
          stylesheet={stylesheet}
          elements={elements}
          maxZoom={1E+1}
          minZoom={1E-1}
        />
      </div>
    </>
  )
}
export default Graph
