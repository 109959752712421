import {gql, useQuery} from '@apollo/client';
import { Accordion, AccordionDetails, AccordionSummary, Card, Container, List, ListItem, Theme, Tooltip, Typography, Button } from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {ReactElement, useContext, useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import sleep from 'sleep-promise';
import {CLIENT} from '../../apollo';
import {CyContext} from '../../App';
import {useAppend, useIncludes, useObjectArrayIncludes, useRemove, useRemoveObject} from '../../atoms/hooks';
import {labelsFilterAtom, labelsFilterSelector} from '../../atoms/labelsFilterAtom';
import {nodesSelector} from '../../atoms/nodesAtom';
import {searchLabelsExpandedAtom} from '../../atoms/searchLabelsExpandedAtom';
import {searchTextAtom} from '../../atoms/searchTextAtom';
import {elementColors} from '../../atoms/stylesheetState';
import {drawerWidth} from '../Drawer/SearchDrawer';
import DisplayError from '../Error/DisplayError';
import DateIcon from '../Icons/DateIcon';
import DescriptionIcon from '../Icons/DescriptionIcon';
import LinkIcon from '../Icons/LinkIcon';
import NameIcon from '../Icons/NameIcon';
import ReviewIcon from '../Icons/ReviewIcon';
import TitleIcon from '../Icons/TitleIcon';
import VersionIcon from '../Icons/VersionIcon';
import { LuceneGuide } from './LuceneGuide';
import ExpandAccordionIcon from '../Icons/ExpandAccordionIcon';

const useStyles = makeStyles(({spacing, palette}: Theme) => ({
  root: {backgroundColor: palette.background.default, width: '100%', margin: 0, padding: 0},
  list: {
     position: 'relative',
    margin: 0, padding: 0, width: '100%',
  },
  card: {
    width: '100%',
    margin: 0, padding: 0, marginRight: spacing(1),
  },
  selected: {borderColor: palette.primary.main, borderWidth: spacing(.3), paddingBottom: spacing(.4), borderStyle: 'solid'},
  listItem: {width: '100%', margin: 0, padding: 0, marginTop: spacing(2)},
  spacer: {flexGrow: 1},
  accordion: {paddingBottom: spacing(1), borderLeftWidth: spacing(1), borderLeftStyle: 'solid', borderLeftColor: palette.background.default},
  skeleton: {margin: 0, padding: 0, borderLeftWidth: spacing(1), borderLeftStyle: 'solid'},
  invalidSearch: {textAlign: 'center'}
}))

//  * Add when nodes have typings
// export const SearchNodesFragments = {
//   SearchNodes: gql`
//     fragment SearchNodes on SearchNodes {
//       id
//     }
//   `
// }

//TODO refactor limit to first to follow neo4j conventions
//TODO implement pagination. This requires a new query and a new component since we don't want to update the pageniation for all labels when the user expands a label
export const SEARCH_NODES = gql`
  query SearchNodes($text: String!, $limit: Int!, $labels: [String]!) {
    SearchNodes(text: $text, limit: $limit, labels: $labels)
  }
  `

const icons = {
  published: <DateIcon />,
  title: <TitleIcon />,
  description: <DescriptionIcon />,
  version: <VersionIcon />,
  review: <ReviewIcon />,
  DOI: <LinkIcon />,
  name: <NameIcon />
}


type Props = {}

const SearchNodes = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const text = useRecoilValue(searchTextAtom)
  const labelsFilter = useRecoilValue(labelsFilterAtom)
  const first = text ? Math.round(300 / labelsFilter.length) : 5
  const labels = labelsFilter.filter(v => v !== 'Author')
  const {data, error, networkStatus, refetch, loading} = useQuery(SEARCH_NODES, {
    variables: {
      text: text || '*',
      limit: first,
      labels,
      
    },
    context: {clientName: CLIENT.neo4j}
  })

  const retry = () => {
    refetch()
  }

  const appendNode = useAppend(nodesSelector)
  const removeNode = useRemoveObject(nodesSelector, {multiple: false})
  const includesNode = useObjectArrayIncludes(nodesSelector)

  const appendLabel = useAppend(labelsFilterSelector)
  const includesLabel = useIncludes(labelsFilterSelector)

  const cyRef = useContext(CyContext)
  const cy = cyRef.current

  const labelIsExpanded = useIncludes(searchLabelsExpandedAtom)
  const expandSearchLabel = useAppend(searchLabelsExpandedAtom)
  const collapseSearchLabel = useRemove(searchLabelsExpandedAtom, {multiple: false})


  useEffect(() => {
    if ([7, 8].includes(networkStatus)) {
      refetch()
    }
  }, [text])

  if (error) {
    console.log("SearchNodes ~ networkStatus", networkStatus)
    switch (networkStatus) {
      case 2:
        return null
        case 7:
          case 8:
        return text.match(/"/g)?.length % 2 === 1 ? <p>The search string must include an even number of quotes</p> :  text.match(/\\/g) ? <p>The escape character "\" is not currently supported</p> :
          <>
            <Typography variant='subtitle1' className={classes.invalidSearch}>Invalid search string</Typography>
              <LuceneGuide/>
            </>
      default:
        return  <DisplayError message={error.message} statusCode={networkStatus} retry={retry} />
    }
  }

  if (!text) return <LuceneGuide />
if (loading) return <List className={classes.list}>{
    labelsFilter.map(label =>
      <Skeleton variant='rect' width={400} height={56} style={{borderLeftColor: elementColors.nodes[label]}} className={classes.skeleton} />
    )}
  </List>

  const onClickNode = async (evt, {nodeIdIncluded, id, label}) => {
    evt.stopPropagation()
    evt.preventDefault()
    const {target} = evt
    if (!nodeIdIncluded) {
      !includesLabel(label) && appendLabel(label)
      const {top} = target.getBoundingClientRect()
      const zoom = cy.zoom()
      const size = 200 / zoom
      const _node = cy.add({data: {}, renderedPosition: {x: drawerWidth + 50, y: top - 36}, style: {width: size, height: size, backgroundColor: 'black'}})
      const {x, y} = _node.position()
      appendNode({id, x, y})
      _node.animate({style: {width: 0, height: 0}, duration: 250})
      await sleep(250)
      _node.remove()
    } else {
      removeNode('id', id)
    }
  }

  const onClickAccordion = (label: string) => labelIsExpanded(label) ? collapseSearchLabel(label) : expandSearchLabel(label)

  const results = JSON.parse(data?.SearchNodes || '[]')
  return (
    <div>
      <Container maxWidth={false} className={classes.root}>
        <List className={classes.list}>
          {
            results.length === 0 ?
              <ListItem className={classes.listItem}>
                <Card className={classes.card}>
                  <Typography variant='subtitle1' >
                    No results found
                  </Typography>
                </Card>
              </ListItem>
              : results.map(([label, nodes]) => {
                const borderLeftColor = elementColors.nodes[label]
                return <Accordion className={classes.accordion} style={{borderLeftColor}} expanded={labelIsExpanded(label)} >
                  <AccordionSummary  onClick={() => onClickAccordion(label)} expandIcon={<ExpandAccordionIcon/>}>
                    {label}
                  </AccordionSummary>
                  <AccordionDetails className={clsx(classes.accordion, classes.listItem)}>
                    <List className={classes.list}>

                      {nodes.map(({id, article_id, ...props}) => {
                        const nodeIdIncluded = includesNode('id', id)
                        const tooltip = `${nodeIdIncluded ? 'Remove' : 'Add '} node`

                        return <ListItem key={id} button className={classes.listItem}>
                          <Tooltip enterDelay={500} title={tooltip} aria-label={tooltip}>
                            <Card
                              className={clsx(classes.card, nodeIdIncluded && classes.selected)}
                              raised={nodeIdIncluded}
                              onClick={(evt) => onClickNode(evt, {nodeIdIncluded, id, label})}
                            >

                              {props && Object.entries(props).map(([key, value]) =>
                                value === 'NaN' ? null : <ListItem key={key} >
                                  <Typography variant='body1'>{value?.includes?.('http') ? <a href={value} target={value}>{value}</a> : key === 'citations' ? 'Citations: ' + (value || 0) : value}</Typography>
                                </ListItem>)}
                            </Card>
                          </Tooltip>
                        </ListItem>
                      })}
                    </List>
                  </AccordionDetails>
                  <Button fullWidth onClick={() => collapseSearchLabel(label)}>Collapse</Button>
                </Accordion>
              }
              )}
        </List>
      </Container >
    </div>
  )
}

export default SearchNodes
