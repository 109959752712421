import {Core, EdgeSingular, EventObject} from 'cytoscape';
import {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {filterDrawerArticleExpandedAtom} from '../../atoms/filterDrawerArticleExpandedAtom';
import {filterDrawerExpandedAtom} from '../../atoms/filterDrawerExpandedAtom';
import {searchDrawerOpenAtom} from '../../atoms/searchDrawerOpenAtom';
import {drawerWidth} from '../Drawer/SearchDrawer';


const useNavigator = (cy: Core | null, {width, height}: {width: number, height: number}) => {

  const setSearchDrawerOpen = useSetRecoilState(searchDrawerOpenAtom)
  const setFilterDrawerExpanded = useSetRecoilState(filterDrawerExpandedAtom)
  const setArticleDrawerExpanded = useSetRecoilState(filterDrawerArticleExpandedAtom)

  const closeDrawers = () => {
    setSearchDrawerOpen(false)
    setFilterDrawerExpanded(false)
    setArticleDrawerExpanded(false)
  }



  useEffect(() => {
    const onTap = async ({target}: EventObject): Promise<void> => {
      const edge = target[0] as EdgeSingular
      const eles = cy.collection().add([edge.source(), edge.target()])
      closeDrawers()
      cy.animate({fit: {eles, padding: 50}})
    }

    cy?.on('tap', 'edge.navigator', onTap)

    return () => {
      cy?.removeListener('tap', 'edge.navigator', onTap)
    }
  }, [cy]);

  const searchDrawerOpen = useRecoilValue(searchDrawerOpenAtom)

  useEffect(() => {
    const onViewport = async (): Promise<void> => {
      const navigator = cy.$id('navigator')
      const pan = cy.pan()
      const zoom = cy.zoom()

      const nodes = cy.nodes()
      if (zoom < .05) {
        nodes.style('height', 20 / zoom)
        nodes.style('width', 20 / zoom)
      } else {
        nodes.style('width', null)
        nodes.style('height', null)
      }

      const zHeight = ((height || 32) - 32) / zoom
      const zWidth = ((width || 32) - 32) / zoom
      navigator.style('height', zHeight)
      navigator.style('width', zWidth)
      navigator.position({x: (-pan.x + width / 2 + (searchDrawerOpen ? drawerWidth : 0)) / zoom, y: (-pan.y + height / 2 + 0) / zoom})
      // @ts-ignore
      cy.style().selector('edge.navigator').style({'arrow-scale': 2 / zoom}).update()
    }

    cy && onViewport()

    cy?.on('viewport', onViewport)
    return () => {
      cy?.removeListener('viewport', onViewport)
    }

  }, [cy, width, height, width, height]);

  useEffect(() => {
    const onMouseOver = async ({target}: EventObject): Promise<void> => {
      const edge = target[0] as EdgeSingular
      edge.data('label',
        edge.target().data('label'))
      const zoom = cy.zoom()
      edge.style('color')
      edge.style('font-size', 30 / zoom)
      edge.style("text-outline-width", .5 / zoom)
      edge.style("width", .01 / zoom)

      const horizontalOffset = 300 / zoom
      const verticalOffset = 100 / zoom

      edge.style('source-text-margin-x',
        edge.target().position().x < edge.source().position().x ?
          `${horizontalOffset}px` : `-${horizontalOffset}px`)

      edge.style('source-text-margin-y',
        edge.target().position().y < edge.source().position().y ?
          `${verticalOffset}px` : `-${verticalOffset}px`)
    }

    cy?.on('mouseover', 'edge.navigator', onMouseOver)

    const onMouseOut = async ({target}: EventObject): Promise<void> => {
      const edge = target[0] as EdgeSingular
      edge.data('label', '')
    }

    cy?.on('mouseover', 'edge.navigator', onMouseOver)
    cy?.on('mouseout', 'edge.navigator', onMouseOut)


    return () => {
      cy?.removeListener('mouseover', 'edge.navigator', onMouseOver)
      cy?.removeListener('mouseOut', 'edge.navigator', onMouseOut)

    }

  }, [cy]);
}

export default useNavigator;

