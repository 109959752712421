import {useEffect, useState} from 'react'
import {useApolloClient, useMutation} from '@apollo/client'
import {CLIENT} from '../../apollo';
import {v4 as uuid} from "uuid"
import {CREATE_USER, GET_USER} from '../../amplifyApi/session';
import {GET_ALL_LABELS, GET_ALL_TYPES} from '../Filter/List';
import {Auth} from 'aws-amplify';

const useGetUser = (id) => {
  const [{data, error, loading, networkStatus}, setResponse] = useState({data: null, error: null, loading: null, networkStatus: null, })
  const [createUser] = useMutation(CREATE_USER, {context: {clientName: CLIENT.amplify}})

  const client = useApolloClient()

  useEffect(() => {
    const context = {clientName: CLIENT.neo4j}
    if (id && !loading && data && !data?.getUser?.id) {
      (async () => {
        const {attributes: {email}} = await Auth.currentUserInfo()
        const [{data: {GetLabels: labels}}, {data: {GetTypes: types}}] = await Promise.all([GET_ALL_LABELS, GET_ALL_TYPES].map(query => client.query({query, context})))
        await createUser({variables: {id, email, labels, types}})
        window.location.reload()
      })()
    }

  }, [data, loading, id]);

  const fetch = async () => {
    const response = await client.query({query: GET_USER, variables: {id}, context: {clientName: CLIENT.amplify}})
    // @ts-ignore
    setResponse(response)
  }
  useEffect(() => {
    id && fetch()
  }, [id]);

  const refetch = fetch

  const user = data?.getUser
  return {loading: loading || !user, error, user, networkStatus, refetch}
}

export default useGetUser
