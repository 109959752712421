import {gql} from '@apollo/client'
import {atom, selector} from 'recoil'
import client, {CLIENT} from '../apollo'
import {sessionIdAtom} from './sessionIdAtom'

const fragment = gql`
  fragment Labels on Session {
    id
    labels
  }
`

export const GET_LABELS = gql`
  query GetSessionLabels($sessionId: ID!) {
    getSession(id: $sessionId) {
     ...Labels
  }
}
  ${fragment}
`

const mutation = gql`
  mutation UpdateLabel($sessionId: ID!, $labels: [String]) {
    updateSession(input: {id: $sessionId, labels: $labels} ) {
      ...Labels
    }
  }
  ${fragment}
`


export const labelsFilterAtom = atom<string[]>(
  {
    default: [],
    key: 'labelsFilterAtom',
  }
)

export const labelsFilterSelector = selector<string[]>({
  key: 'labelsFilterSelector',
  get: ({get}) => get(labelsFilterAtom),
  set: ({get, set}, labels) => {
    const sessionId = get(sessionIdAtom)
    client.mutate({mutation, variables: {sessionId, labels}, context: {clientName: CLIENT.amplify}}).catch(err => {
      throw err
    })
    set(labelsFilterAtom, labels)
  }
}
)
