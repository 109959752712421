import {gql} from '@apollo/client'
import {atom, selector} from 'recoil'
import client, {CLIENT} from '../apollo'
import {sessionIdAtom} from './sessionIdAtom'

const fragment = gql`
  fragment Types on Session {
    id
    types
  }
`

export const GET_TYPES = gql`
  query GetSessionTypes($sessionId: ID!) {
    getSession(id: $sessionId) {
     ...Types
  }
}
  ${fragment}
`

const mutation = gql`
  mutation UpdateTypes($sessionId: ID!, $types: [String]) {
    updateSession(input: {id: $sessionId, types: $types} ) {
      ...Types
    }
  }
  ${fragment}
`


export const typesFilterAtom = atom<string[]>(
  {
    default: [],
    key: 'typesFilterAtom',
  }
)

export const typesFilterSelector = selector<string[]>({
  key: 'typesFilterSelector',
  get: ({get}) => get(typesFilterAtom),
  set: ({get, set}, types) => {
    const sessionId = get(sessionIdAtom)
    set(typesFilterAtom, types)
    client.mutate({mutation, variables: {sessionId, types}, context: {sessionName: CLIENT.amplify}}).catch(err => {
      throw err
    })
  }
}
)
