import {ApolloQueryResult, gql, useApolloClient} from '@apollo/client'
import {useState} from 'react'
import {CLIENT} from '../../apollo'

export const GET_NEIGHBORS = gql`
  query GetNeighbors($ids: [ID]!, $limit: Int) {
    GetNeighbors(ids: $ids, limit: $limit)
  }
`

export const useGetNeighbors = (ids?: string[]) => {
  const [query, set] = useState<ApolloQueryResult<{GetNeighbors: string[]}>>({
    data: null,
    errors: null,
    error: null,
    loading: null,
    networkStatus: null,
    partial: null,
  })

  const client = useApolloClient()

  const get = async (overwriteIds?: string[]) => {
    const result = await client.query({query: GET_NEIGHBORS, variables: {ids: overwriteIds || ids}, context: {clientName: CLIENT.neo4j}})
    set(result)
    return result
  }



  return {get, ...query}
}
