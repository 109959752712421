import {AppBar, createStyles, makeStyles, Theme, Toolbar, Tooltip} from '@material-ui/core'
import {useSetRecoilState} from 'recoil'
import scipleIcon from '../../assets/images/sciple-square-icon.png'
import {sessionIdSelector} from '../../atoms/sessionIdAtom'
import Fit from '../Graph/Fit'
import Layout from '../Graph/Layout'
import Redo from '../Graph/Redo'
import Undo from '../Graph/Undo'
import Spacer from '../Layout/Spacer'
import ToggleSearchDrawer from '../Search/ToggleSearchDrawer'
import More from './Menu'
import Clear from '../Graph/Clear'

const useStyles = makeStyles((theme: Theme) => createStyles({

  appBar: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(8),
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarImage: {
    margin: '20px',
    maxHeight: '35px',
  },
  clear: {paddingRight: theme.spacing(2)},
  icon: {
    height: theme.spacing(4),
    paddingRight: theme.spacing(1),
    width: theme.spacing(4),
  },
  toolbar: {
    color: 'blue',
    paddingRight: 24,
  },
}))
const GraphAppBar = () => {
  const classes = useStyles({})
  const setSessionId = useSetRecoilState(sessionIdSelector)
  // const showSessions = () => setSessionId('')
  return (
    <AppBar
      position='relative'
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Tooltip enterDelay={500} title='Sciple homepage'>
          <a href='https://www.sciple.ai/'>
          <img src={scipleIcon} className={classes.icon} /> 
          </a>
           {/* onClick={showSessions} /> */}
        </Tooltip>
        <ToggleSearchDrawer />
        <Layout />
        <Fit />
        <Undo />
        <Redo />
        <Clear />
        <Spacer />
        <More />
      </Toolbar>
    </AppBar>
  )
}

export default GraphAppBar

