import React, {ReactElement} from 'react'
import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(({ }: Theme) => ({
  root: {flexGrow: 1, display: 'flex', backgroundColor: 'black', width: '100%'}
}))


type Props = {}

const Spacer = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  return (
    <div className={classes.root}>

    </div>
  )
}

export default Spacer
