import {ReactElement, useState} from 'react'
import {Card, CardActionArea, CardActions, CardContent, CardHeader, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import AddIcon from '../Icons/AddIcon'
import {useMutation, useQuery} from '@apollo/client'
import {useRecoilValue} from 'recoil'
import {CREATE_SESSION} from '../../amplifyApi/session'
import {CLIENT} from '../../apollo'
import {currentUserIdState} from '../../atoms/cognitoUserAtom'
import {GET_USER_SESSION} from './Sessions'
import {v4 as uuid} from 'uuid'
import {GET_ALL_LABELS, GET_ALL_TYPES} from '../Filter/List'
import AddSessionPrompt from './AddSessionPrompt'

const useStyles = makeStyles(({ }: Theme) => ({
  content: {
    display: 'flex',
    height: '369px',
    width: '100%'
  },
  icon: {
    display: 'block',
    height: '70px',
    margin: 'auto',
    width: '70px'
  },
  root: {},
}))

type Props = {}

const AddSessionCard = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props
  const currentUserId = useRecoilValue(currentUserIdState)

  const labelQuery = useQuery(GET_ALL_LABELS, {context: {clientName: CLIENT.neo4j}})
  const typeQuery = useQuery(GET_ALL_TYPES, {context: {clientName: CLIENT.neo4j}})

const [name, setName] = useState('');
  const [mutate] = useMutation(CREATE_SESSION, {
    context: {clientName: CLIENT.amplify},
    refetchQueries: [{
      context: {clientName: CLIENT.amplify},
      query: GET_USER_SESSION,
      variables: {
        filter: {archived: {ne: true}},
        id: currentUserId
      }
    }],
    variables: {
      session: {
        id: uuid(),
        labels: labelQuery.data?.GetLabels || [],
        name,
        nodes: [],
        types: typeQuery.data?.GetTypes || [],
        userSessionsId: currentUserId,
      },
    },
  })


  const [prompt, setPrompt] = useState(false);
  const onClick = () => setPrompt(true)

  const onConfirm = () => {
    mutate()
    setPrompt(false)
    setName('')
  }

  return (<>
    <AddSessionPrompt open={prompt} onConfirm={onConfirm} name={name} setName={setName} onCancel={() => setPrompt(false)} />
    
    <Card className={classes.root}>
   
      <CardActionArea onClick={onClick}>
        <CardHeader title='Create new map' />
        <CardContent className={classes.content}>
          <AddIcon className={classes.icon} />
        </CardContent>
        <CardActions />
      </CardActionArea>
    </Card>
    </>
  )
}

export default AddSessionCard
