import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import Interweave from 'interweave'
import {MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState} from 'react'
//@ts-ignore
import ReactMarkdown from 'react-markdown'
import htmlParser from 'react-markdown/plugins/html-parser'
import {v4 as uuid} from 'uuid'
import {useRemoveObject} from '../../atoms/hooks'
import {searchHighlightsAtom} from '../../atoms/searchHighlightsAtom'
import {elementForeground} from '../../atoms/stylesheetState'
import {drawerWidth} from '../Drawer/SearchDrawer'

const useStyles = makeStyles(({ }: Theme) => ({
  comment: {
    color: 'yellow'
  },
  note: {
    padding: '0 0 8px 8px'
  },
  root: {
    // height:
    //   `calc(100vh - ${200}px)`,
    width: drawerWidth

  },
  hidden: {
    position: 'absolute',
    width: drawerWidth,
    zIndex: 0, backgroundColor: 'white',
  },
  displayed: {
    overflow: 'scroll', width: drawerWidth, zIndex: 1000, backgroundColor: 'white'
  },
  spacer: {height: '1000vh', width: drawerWidth}
}))

type Mark = {color: string, index: number, end: number, start: number, id: string}

type MarkdownHighlight = {marks: Mark[], index: number}

type HighlightSpan = {marks: Mark[], innerText: string}

const getStartSpan = ({marks, innerText}: HighlightSpan) => innerText.substring(0, marks[0].start)

export const idDelimeter = '_'



const getMiddleSpans = ({marks, innerText, parentId}: HighlightSpan & {parentId: string}) =>
  marks.map((mark, i) => `<span id='${mark.id}' class='markdown-span ${`${parentId}${idDelimeter}${i}${idDelimeter}`}' style='background:${marks[i].color};'>${innerText.substring(marks[i].start, marks[i].end)}</span>${i === marks.length - 1
    ? ''
    : `<span id=end:${mark.id} class='markdown-span end:${parentId}${idDelimeter}${i}'>${innerText.substring(marks[i].end, marks[i + 1].start)}</span>`}`).join('')

const getEndSpan = ({marks, innerText}: HighlightSpan) => innerText.substring(marks[marks.length - 1].end, innerText.length)

const getSpans = ({marks, innerText, parentId}: HighlightSpan & {parentId: string}) => {
  const startSpan = getStartSpan({
    innerText,
    marks
  })
  const endSpan = getEndSpan({
    innerText,
    marks
  })
  const middleSpans = getMiddleSpans({
    innerText,
    marks,
    parentId
  })
  return `<span id='start:${marks[marks.length - 1].id}' class='markdown-span ${`${parentId}${idDelimeter}${0}`}'>${startSpan}</span>${middleSpans}<span class='markdown-span ${`${parentId}${idDelimeter}${marks.length}`}'>${endSpan}</span>`
}

const applyHighlights = ({div, highlights, setHighlightedText}: {div: Element, highlights: MarkdownHighlight[], setHighlightedText}) => <div>
  {highlights.map(({marks, index}) => {
    const child = Array.from(div.children)?.[index]
    if (!child) return
    const {innerText} = child as HTMLParagraphElement
    const newText = getSpans({
      innerText,
      marks,
      parentId: child.classList.item(1)
    })
    setHighlightedText(newText)
  })}
</div>

const attachClasssToParagraphs = ({
  div,
  paragraphClass
}:
  {
    div: Element,
    paragraphClass: string
  }) => {

  //TODO use the markdown ID for this
  div.id = uuid()
  if (div) {
    const children = Array.from(div.children)
    children?.forEach((c, i) => {
      const pClass = `${paragraphClass}-${i}`
      c.classList.add('paragraph')
      c.classList.add(pClass)

      Array.from(c.children || []).forEach((div, j) => {
        attachClasssToParagraphs({
          div,
          paragraphClass: `${pClass}-${j}`
        })
      })
    })
  }
}



export const lowerCase = (string: string) =>
  string[0].toLowerCase() + string.substring(1);
export const capitalize = (string: string) =>
  string[0].toUpperCase() + string.substring(1);

const escapeString = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const formatSearchString = (searchString: string) => {
  const s = escapeString(searchString)
  return ["\\b" + s + "\\b", "\\b" + capitalize(s) + "\\b"].join("|")
}

const applySearchHighlights = ({div, searchHighlights, setHighlightedText}) => {
  const text = searchHighlights.reduce((acc, {searchString, color}) => acc
    .replace(
      new RegExp(`(?!<.?)(${formatSearchString(searchString)})(?!.?>)`, "g"),
      (matched) =>
        matched
          ? `
          <span style="
          background-color: ${color};
          color:${elementForeground(color)};"
          class="markdown-span"
          >${matched}</span>
          `
          : matched,
    )
    , div.innerHTML) as unknown as string
  setHighlightedText(text)
}

type Props = {
  text?: string, highlights?: any, refetchArticle?: () => void, searchHighlights?: {searchString: string, color: string}[]
}

const Markdown = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const text = props.text || ''

  const [paragraphsIdsAdded, setParagraphsIdsAdded] = useState(false)

  // const [selectionChangedAt, setSelectionChangedAt] = useState('')
  // const onMouseUp = () => {
  //   setSelectionChangedAt(Date.now().toString())
  // }

  const ref = useRef<Element | null>(null)

  const {highlights, searchHighlights} = props

  const markdown = useMemo<ReactElement>(() => (
    <div
      ref={ref as MutableRefObject<HTMLDivElement | null>}
    >
      <ReactMarkdown
        className={classes.hidden}
        //@ts-ignore 
        source={text}
        //@ts-ignore
        htmlParser={htmlParser}
      >
        {text}
      </ReactMarkdown>
    </div>
  ), [text])

  useEffect(() => {
    if (ref.current) {
      !paragraphsIdsAdded && attachClasssToParagraphs({
        div: ref.current,
        paragraphClass: 'paragraph',
      })
      setParagraphsIdsAdded(true)
    }
  }, [markdown, paragraphsIdsAdded])

  const [highlightedText, setHighlightedText] = useState('');

  useEffect(() => {
    if (ref.current) {
      const div = ref.current
      highlights && applyHighlights({
        div,
        highlights,
        setHighlightedText,
      })
      searchHighlights && applySearchHighlights({
        div,
        searchHighlights,
        setHighlightedText,
      }
      )

    }
  }, [markdown, paragraphsIdsAdded, highlights, searchHighlights])

  const removeSearchHighlight = useRemoveObject(searchHighlightsAtom, {multiple: false})

  useEffect(() => {
    // const _onClick = ({target}) => {
    //   const range = document.createRange()
    //   range.selectNodeContents(target)
    //   const sel = window.getSelection()
    //   sel?.removeAllRanges()
    //   sel?.addRange(range)
    // }

    const spans = document.getElementsByClassName('markdown-span')
    //@ts-ignore
    Array.from(spans).forEach(s => s.onclick = ({target}) => removeSearchHighlight('searchString', target.innerHTML))
    //@ts-ignore 
    // Array.from(spans).forEach(s => s.onmouseup = onMouseUp)

  }, [highlightedText]);

  // const [open, toggle] = useToggle(false)
  // const note = `# Aliquet
  // Eu vulputate aliquet mollis et taciti donec`
  // // const cancel = 'cancel'
  // const done = 'Done'

  return (
    <>
      <div className={classes.hidden}>

        {markdown}
      </div>
      <div
        className={classes.root}
      >
        <Interweave content={highlightedText} className={classes.displayed} />
      </div>
    </>

  )
}

export default Markdown

      // <SelectionPopper
      //   placement='bottom-start'
      //   selectionChangedAt={selectionChangedAt}
      // >

      //   {/* {open ? */}
      //   <Card className={classes.note}>
      //     <CardContent>
      //       <ReactMarkdown
      //       // source={''}
      //       // includeNodeIndex={true}
      //       >
      //         {note}
      //       </ReactMarkdown>
      //     </CardContent>
      //     {/* <CardContent>
      //     <PersonIcon />
      //     {highlights[0]?.marks[0].person.name}
      //   </CardContent> */}
      //     {['red', 'cyan', 'green', 'orange'].map(c =>
      //       <IconButton key={c} >
      //         <FiberManualRecord style={{color: c}} />
      //       </IconButton>)}
      //     <CardActions>
      //       {/* <Button onClick={toggle}>
      //         {cancel}
      //       </Button> */}
      //       <Button
      //         onClick={() => {
      //           toggle()
      //           refetchArticle?.()
      //         }}
      //       >
      //         {done}
      //       </Button>
      //     </CardActions>
      //   </Card>
      //   {/* : (
      //       <div>a</div>
      //       // <AddComment
      //       //   onComplete={toggle}
      //       //   articleId={articleId}
      //       // />
      //     )
      //   } */}
      // </SelectionPopper>
