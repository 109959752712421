import {gql, useQuery} from '@apollo/client'
import {Button, Card, CardActions, CardHeader, Checkbox, Chip, Dialog, IconButton, List, ListItem, makeStyles, TextField, Toolbar, Tooltip} from '@material-ui/core'
import {SwapHoriz} from '@material-ui/icons'
import {Autocomplete} from '@material-ui/lab'
import clsx from 'clsx'
import React, {useState} from 'react'
import {useRecoilState} from 'recoil'
import {CLIENT} from '../../apollo'
import {useIncludes} from '../../atoms/hooks'
import {labelsFilterSelector} from '../../atoms/labelsFilterAtom'
import {elementColors, elementForeground} from '../../atoms/stylesheetState'
import {typesFilterSelector} from '../../atoms/typesFilterAtom'
import DisplayError from '../Error/DisplayError'
import EdgeIcon from '../Icons/EdgeIcon'
import NodeIcon from '../Icons/NodeIcon'
import Spacer from '../Layout/Spacer'

const useStyles = makeStyles(({palette}) => ({
  root: {
    width: '100%',
  },
  toolbar: {padding: 0},
  switch: {background: palette.primary.main},
  remove: {color: palette.error.dark},
  add: {color: palette.primary.dark}
}))

export const GET_ALL_LABELS = gql`
  query GetLabels {
    GetLabels
  }
`

export const GET_ALL_TYPES = gql`
  query GetTypes {
    GetTypes
  }
`


const selectGetAll = {nodes: GET_ALL_LABELS, edges: GET_ALL_TYPES}

export type Variant = 'nodes' | 'edges'

const FilterList = ({expanded}: {expanded: boolean} = {expanded: false}) => {

  const classes = useStyles()
  const [variant, setVariant] = useState<Variant>('nodes');
  const toggle = () => setVariant(variant === 'nodes' ? 'edges' : 'nodes')
  const getAll = selectGetAll[variant]

  const [labels, setLabels] = useRecoilState(labelsFilterSelector)
  const [types, setTypes] = useRecoilState(typesFilterSelector)

  const setStateOptions = {nodes: setLabels, edges: setTypes}
  const setState = setStateOptions[variant]
  const selectedOptions = {nodes: labels, edges: types}
  const selected = selectedOptions[variant]
  const {data, error, loading, networkStatus} = useQuery(getAll, {context: {clientName: CLIENT.neo4j}})

  const includesLabel = useIncludes(labelsFilterSelector)
  const includesType = useIncludes(typesFilterSelector)
  const includes = {nodes: includesLabel, edges: includesType}

  const [openRemoveAll, setOpenRemoveAll] = useState(false);

  const onClickRemoveAll = () => setOpenRemoveAll(true)

  const onClickConfirmRemoveAll = () => {
    setOpenRemoveAll(false)
    setState([])
  }


  const [openAddAll, setOpenAddAll] = useState(false);

  const onClickAddAll = () => setOpenAddAll(true)

  const all = data?.['Get' + (variant === 'nodes' ? 'Labels' : 'Types')]
  const onClickConfirmAddAll = () => {
    setOpenAddAll(false)
    setState(all)
  }


  if (loading || !labels || !types) return <p>Loading...</p>
  if (error) return <DisplayError message={error.message} statusCode={networkStatus} />


  const allSelected = (selected.length || 0) === all.length

  const handleToggle = (value: string) => () => {
    selected.includes(value) ? setState((current) => current.filter(v => v !== value)) : setState((current) => ([...current, value]))
  }

  const tooltip = `Show filter for ${variant === 'nodes' ? 'relationships' : 'entities'}`

  return (
    <>
      {expanded ?
        <>
          <Toolbar className={classes.toolbar}>
            <Tooltip enterDelay={500} title="Filter entities" aria-label="Filter nodes">
              <Button fullWidth color='primary' variant={variant === 'nodes' ? 'contained' : 'outlined'} onClick={toggle}>
                Entities
              </Button>
            </Tooltip>
            <Tooltip enterDelay={500} title="Filter relationships" aria-label="Filter edges">
              <Button fullWidth color='primary' variant={variant === 'edges' ? 'contained' : 'outlined'} onClick={toggle}>
                Relationships
              </Button>
            </Tooltip>
          </Toolbar>

          <Toolbar className={classes.toolbar}>
            <Button fullWidth disabled={allSelected}
              onClick={onClickAddAll}>
              {allSelected ? 'All are included' : 'Include all'}
            </Button>
            <Button fullWidth disabled={selected.length === 0}
              onClick={onClickRemoveAll} className={classes.remove}
            >
              Remove all
            </Button>
          </Toolbar>
          <Dialog open={openAddAll}>
            <Card>
              <CardHeader title={`Add all ${variant=== 'nodes' ? 'entities' : 'relationships'} types?`} variant='subtitle1' className={classes.add} />
              <CardActions>
                <Button onClick={() => setOpenAddAll(false)}>Cancel</Button>
                <Spacer />
                <Button onClick={onClickConfirmAddAll} className={classes.add}>Confirm</Button>
              </CardActions>
            </Card>
          </Dialog>
          <Dialog open={openRemoveAll}>
            <Card>
              <CardHeader title={`Remove all ${variant=== 'nodes' ? 'entities' : 'relationships'} types?`} variant='subtitle1' className={classes.remove} />
              <CardActions>
                <Button onClick={() => setOpenRemoveAll(false)}>Cancel</Button>
                <Spacer />
                <Button onClick={onClickConfirmRemoveAll} className={classes.remove}>Confirm</Button>
              </CardActions>
            </Card>
          </Dialog>
          <Autocomplete
            disableClearable
            multiple
            disableCloseOnSelect
            options={all}
            value={selected}
            onChange={(_event, newValue) => {
              setState(newValue as string[])
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  style={{backgroundColor: elementColors.nodes[option as unknown as string], color: elementForeground(elementColors.nodes[option as unknown as string])}}
                  label={`${option as unknown as string}`}
                  {...getTagProps({index})}
                />
              ))
            }

            renderInput={(params) => <TextField {...params} variant="outlined" label="Included" placeholder="Type" />
            }
            renderOption={(option) => (
              <>
                <Checkbox
                  checked={selected.indexOf(option as unknown as string) !== -1}
                  onChange={() => handleToggle(option as unknown as string)}
                />
                {option}
              </>
            )
            }
          />
        </>
        :
        <>
          <Toolbar onClick={toggle} className={clsx(classes.toolbar)}>
            <Spacer />
            <Tooltip enterDelay={500} title={tooltip} aria-label={tooltip}>
              <IconButton>
                <SwapHoriz />
              </IconButton>
            </Tooltip>
            <Spacer />
          </Toolbar>
          <List
            dense
            className={classes.root}
          >
            {all.map((option) => (
              <ListItem
                selected={selected.indexOf(option) !== -1}
                key={variant + '-' + option}
                onClick={handleToggle(option)}
                style={{backgroundColor: 'transparent'}}
              >
                <Spacer />
                <Tooltip enterDelay={500} title={option} placement="left">
                  <IconButton
                    size="small"
                    onClick={() => handleToggle(option)}
                    style={{
                      backgroundColor: elementColors[variant][option],
                      color: elementForeground(elementColors[variant][option]),
                      opacity: includes[variant](option) ? 1 : 0.2
                    }}>
                    {variant === 'nodes' ? <NodeIcon style={{opacity: 0}} /> : <EdgeIcon />}
                  </IconButton>
                </Tooltip>
                <Spacer />
              </ListItem>
            ))}
          </List>
        </>
      }
    </>
  )
}

export default FilterList
