import {RecoilState, useRecoilValue, useSetRecoilState} from 'recoil'

export const useAppend = (selector: RecoilState<any>) => {
  const set = useSetRecoilState(selector)
  return (v: any) =>
    set(current => [
      ...current,
      v
    ].flat().filter((v, i, a) => a.indexOf(v) === i))
}

export const useAppendObject = (selector: RecoilState<any>) => {
  const set = useSetRecoilState(selector)
  return (v: any) =>
    set((current) => ({...current, ...v}))
}


export const useRemove = (selector: RecoilState<any>, {multiple}: {multiple: boolean}) => {
  const set = useSetRecoilState<any[]>(selector)
  return (v: any) => set(current =>
    current.filter(x => (multiple ? !v.includes(x) : v !== x)))
}

export const useRemoveObject = (selector: RecoilState<any>, {multiple}: {multiple: boolean}) => {
  const set = useSetRecoilState<any[]>(selector)
  return (k: string, v: any) => set(current => current.filter(x => (multiple ? !v.includes(x[k]) : v !== x[k])))
}

export const useIncludes = (selector: RecoilState<any>) => {
  const value = useRecoilValue<any[]>(selector)
  return (v: any) => value.includes(v)
}

export const useObjectIncludes = (selector: RecoilState<any>) => {
  const value = useRecoilValue<any[]>(selector)
  return (v: any) => !!value[v]
}


export const useObjectArrayIncludes = (selector: RecoilState<any>) => {
  const value = useRecoilValue<any[]>(selector)
  return (k: string, v: any) => value.some(x => x[k] === v)
}
