import {IconButton, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {ReactElement} from 'react'
import {useSetRecoilState} from 'recoil'
import {sessionIdSelector} from '../../atoms/sessionIdAtom'
import GridIcon from '../Icons/GridIcon'

const useStyles = makeStyles(({ }: Theme) => ({
  root: {},
}))

type Props = {}

const ShowSessions = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const setId = useSetRecoilState(sessionIdSelector)
  const show = () => setId('')

  return (
    <div className={classes.root}>
      <IconButton onClick={show}>
        <GridIcon />
      </IconButton>
    </div>
  )
}

export default ShowSessions
