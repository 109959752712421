import {ReactElement} from 'react'
import {Button, Card, CardActions, CardContent, CardHeader, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(({ }: Theme) => ({
  root: {},
  header: {},
  content: {},
  button: {},
}))


type Props = {error?: any, message: string, statusCode: number, retry?: () => void, report?: () => void, buttons?: ReactElement | ReactElement[]}

const DisplayError = (props: Props): ReactElement => {
  const classes = useStyles({})
  const {message, statusCode, retry, error, report, buttons} = props

  error && console.log(error)

  return (
    <Card>
      <CardHeader className={classes.header} title={statusCode} />
      <CardContent className={classes.content}>
        <p>{message}</p>
      </CardContent>
      <CardActions>
        {retry && <Button className={classes.button} onClick={retry}>Retry</Button>}
        {report && <Button className={classes.button} onClick={report}>Report</Button>}
        {buttons}
      </CardActions>
    </Card>
  )
}

export default DisplayError
