import {IconButton, Tooltip} from '@material-ui/core'
import {ReactElement} from 'react'
import {useSetRecoilState} from 'recoil'
import RedoIcon from '../Icons/RedoIcon'
import {nodesHistory, useCanChangeHistory} from '../../atoms/nodesAtom'

const Redo = (): ReactElement => {

  const redo = useSetRecoilState(nodesHistory('redo'))
  const enabled = useCanChangeHistory('redo')

  const onClick = () => redo()

  return (
    <Tooltip enterDelay={500} title="Redo" aria-label="redo">
      <IconButton onClick={onClick} disabled={!enabled}>
        <RedoIcon />
      </IconButton>
    </Tooltip>
  )
}

export default Redo
