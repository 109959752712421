import {Stylesheet} from 'cytoscape';
import distinctColors from 'distinct-colors';
import {atom} from 'recoil';
import tinycolor from 'tinycolor2';
import collapse from '../components/Graph/images/collapse.svg';
import expand from '../components/Graph/images/expand.svg';
import remove from '../components/Graph/images/remove.svg';



// TODO:  and fetch from database instead of hardcoded
const labels = ["navigator", "Person", "Article", "Disease", "Chemical", "AminoAcid", "AnatomicalSystem", "Cancer", "Cell", "CellLine", "CellType", "CellularComponent", "Chebi", "Cl", "DevelopingAnatomicalStructure", "Dna", "GeneOrGeneProduct", "Ggp", "Go", "ImmaterialAnatomicalEntity", "MultiTissueStructure", "Organ", "Organism", "OrganismSubdivision", "OrganismSubstance", "PathologicalFormation", "Protein", "Rna", "SimpleChemical", "So", "Taxon", "Tissue"]
const labelColors = ["rgba(0,0,0,0)", "rgb(147, 222, 70)", "rgb(3, 128, 244)", "rgb(234, 200, 55)", "rgb(28, 135, 213)", "rgb(220, 99, 26)", "rgb(55, 207, 250)", "rgb(235, 65, 100)", "rgb(36, 228, 155)", "rgb(209, 106, 171)", "rgb(73, 144, 22)", "rgb(173, 136, 231)", "rgb(197, 230, 143)", "rgb(64, 174, 241)", "rgb(151, 124, 28)", "rgb(57, 231, 240)", "rgb(244, 160, 96)", "rgb(34, 148, 160)", "rgb(243, 143, 159)", "rgb(24, 148, 90)", "rgb(234, 188, 245)", "rgb(112, 143, 91)", "rgb(244, 205, 224)", "rgb(139, 222, 185)", "rgb(139, 126, 159)", "rgb(239, 202, 147)", "rgb(106, 116, 115)", "rgb(220, 232, 201)", "rgb(150, 152, 147)", "rgb(178, 227, 242)", "rgb(189, 190, 186)", "rgb(238, 232, 233)", "rgb(156, 111, 92)", "rgb(139, 99, 234)"]

export const elementColors = {
  edges: {"MENTIONS": "blue", "AUTHORED": 'green'},
  nodes: Object.fromEntries(labels.map((label, i) => [label, labelColors[i]]))
}

const _generateColors = (labels: string[]) => distinctColors({count: labels.length, lightMin: 40}).map((color) => {
  const [r, g, b] = color.rgb()
  return `rgb(${r}, ${g}, ${b})`
});

export const elementForeground = (background: string) => tinycolor(background).getBrightness() < 128 ? 'white' : 'black'


// TODO: Handle multiple labels for colors

const hidden = {style: {opacity: 0}}
const defaultColors = ['white', 'black']
export const defaultStylesheet = (theme: 'light' | 'dark'): Stylesheet[] => {
  if (!theme) {throw new Error('Theme must be defined')}
  const [foreground, background] = theme === 'dark' ? defaultColors : defaultColors.reverse()
  return [{
    selector: 'node[label]',
    css: {
      "label": "data(label)",
      "font-size": '10',
      "text-wrap": "wrap",
      "text-max-width": '200',
      "text-outline-color": background,
      "text-outline-opacity": 1,
      "text-outline-width": 1.5
    }
  },
  {
    selector: 'edge',
    style: {
      "label": "",
      "font-size": '10',
      "text-wrap": "wrap",
      "text-max-width": '200',
      "line-fill": "linear-gradient",
      "curve-style": "straight",
      "target-arrow-shape": "triangle",
      "arrow-scale": .7,
      "text-outline-color": background,
      "text-outline-opacity": 1,
      "text-outline-width": 1.5,
      "width": '1'
    }
  },
  ...Object.entries(elementColors.edges).map(
    ([key, color]) => ({
      selector: '.' + key,
      style: {
        backgroundColor: color,
        color: color,
        "line-gradient-stop-colors": `${background} ${color} ${color} ${color}`,
        "target-arrow-color": color
      }
    })),
  ...Object.entries(elementColors.nodes).map(
    ([key, color]) => ({
      selector: '.' + key,
      style: {
        backgroundColor: color,
        color
      }
    })),
  {
    selector: 'edge[label].highlight[label]',
    style: {
      "width": '8',
      "label": "data(label)"
    }
  }, {
    selector: 'node.highlight',
    style: {
      "width": 40,
      "height": 40

    }
  },
  {
    selector: 'edge[label]:selected',
    style: {
      "width": '10',
      "label": "data(label)"
    }
  }, {
    selector: 'node:selected',
    style: {
      "width": 50,
      "height": 50

    }
  },

  {selector: 'node.hidden', ...hidden},
  {selector: 'edge.hidden', ...hidden},
  {
    selector: 'node.navigator',
    style: {
      shape: 'rectangle',
      events: 'no',
      label: "data(label)",
      "text-halign": 'center',
      "text-valign": 'center',
      opacity: 0,
    },
  },
  {
    selector: 'edge.navigator',
    style: {
      width: .01,
      "source-arrow-shape": 'circle',
      "target-arrow-shape": 'none',
      "arrow-scale": 1,
      "source-arrow-color": (e) => elementColors.nodes[e.target().data('types')[0]],
      "color": (e) => elementColors.nodes[e.target().data('types')[0]],

    }
  },
  {
    selector: 'edge[label].navigator',
    style: {
      "source-label": 'data(label)',
    }
  },
  {
    selector: 'node.cxtmenu',
    style: {
      "width": 25,
      "height": 25,
      "font-size": 20,
      "text-outline-width": 1.5,
      "background-color": background,
      'background-fit': 'cover',
      "text-outline-color": background,
      'z-index': 4100

    }
  },
  {
    selector: 'node.cxtmenu-background',
    style: {
      opacity: 1,
      width: 100,
      height: 100,
      'background-color': foreground,
      'background-opacity': .7,
      'border-color': foreground,
      'z-index': 4000
    }
  },
  {
    selector: 'node.expand',
    style: {
      "background-image": expand,
      "label": 'data(count)'
    }
  },
  {
    selector: 'node.remove',
    style: {
      "background-image": remove,

    }
  }, {
    selector: 'node.collapse',
    style: {
      "background-image": collapse,
    }
  },
  {
    selector: 'node.expand.hover',
    style: {
      "label": (e) => "Expand " + (e.cy().nodes(':selected').length > 0 ? "selected" : e.data('count'))
    }
  },
  {
    selector: 'node.remove.hover',
    style: {
      "label": (e) => 'Remove' + (e.cy().nodes(':selected').length > 0 ? " selected" : '')

    }
  }, {
    selector: 'node.collapse.hover',
    style: {
      "label": (e) => "Collapse" + (e.cy().nodes(':selected').length > 0 ? " selected" : '')
    }
  },
  {
    selector: 'node.neighbor',
    style: {
      width: 10,
      height: 10,
      // events: 'no',
      "font-size": 7,
      "text-outline-color": background,
      "text-outline-opacity": 1,
      "text-outline-width": .5,
      "text-max-width": '100',

    }
  },

  ]
}

export const localStylesheetState = atom<Stylesheet[]>(
  {
    default: defaultStylesheet('light'),
    key: 'localStylesheet'
  }
)

export const stylesheetState = atom<Stylesheet[] | null>(
  {
    default: null,
    key: 'stylesheet'
  }
)
