import {useApolloClient} from '@apollo/client';
import {Core, EventObject} from 'cytoscape';
import {useEffect} from 'react';
import {CLIENT} from '../../apollo';
import {useGetArticle} from '../Article/useGetArticle';
import {GET_NEIGHBORS} from './useGetNeighbors';

const usePreFetchOnHover = (cy: Core) => {
  const client = useApolloClient()

  const getArticle = useGetArticle();

  useEffect(() => {
    const handler = ({target: [node]}: EventObject) => {
      const id = node.id()
      const ids = [id]
      client.query({query: GET_NEIGHBORS, variables: {ids}, context: {clientName: CLIENT.neo4j}})
      node.data('types').includes('Article') && getArticle(id, {updateState: false})
    }

    cy?.on('tapstart mouseover', 'node[isElement]', handler)

    return () => {
      cy?.removeListener('tapstart mouseover', 'node[isElement]', handler)
    }
  }, [cy])
}

export default usePreFetchOnHover
