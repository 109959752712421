import {ReactElement} from 'react'
import { Drawer, Theme, Toolbar, Paper } from '@material-ui/core';
import {makeStyles} from '@material-ui/styles'
import SearchNodes from '../Search/SearchNodes'
import {searchDrawerOpenAtom} from '../../atoms/searchDrawerOpenAtom'
import {useRecoilValue} from 'recoil';
import SearchField from '../Search/SearchField'
export const drawerWidth = 400

const useStyles = makeStyles(({ palette,spacing}: Theme) => ({
  root: {overflow: 'hidden'},
  toolbar: {marginLeft:-spacing(0)},
  searchField: {position:'absolute', width:'100%', backgroundColor: palette.background.default, zIndex: 3000, margin:0},
  container: {width: drawerWidth, overflow: 'scroll'},
  paper: {width: '100%', margin: 0, marginLeft: - spacing(3), padding: 0}
}))

type Props = {}

const SearchDrawer = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const open = useRecoilValue(searchDrawerOpenAtom)

  return (
    <Drawer
      variant='permanent'
      className={classes.root}
      open={open} >
      {open &&
        <div className={classes.container} >
        <Toolbar className={classes.searchField} >
        <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar} />
          <SearchField />
        </Paper>
        </Toolbar>
        <Toolbar className={classes.toolbar} />
        <Toolbar className={classes.toolbar} />

          <SearchNodes />
        </div>
      }
    </Drawer>
  )
}

export default SearchDrawer
