import {useApolloClient} from '@apollo/client';
import {Core, NodeDefinition, NodeSingular} from 'cytoscape';
import {useEffect, useRef} from 'react';
import {useSearchParam} from 'react-use';
import {useRecoilValue} from 'recoil';
import {CLIENT} from '../../apollo';
import {useAppend, useIncludes, useRemoveObject} from '../../atoms/hooks';
import {labelsFilterSelector} from '../../atoms/labelsFilterAtom';
import {nodesSelector} from '../../atoms/nodesAtom';
import {GET_NEIGHBORS} from './useGetNeighbors';


const {sin, cos, PI} = Math
const TAU = PI * 2
const DISTANCE = 75

//TODO: Define @types/cytoscape-cxtmenu

const OFFSET = 38

export const getCircle = ({zoom, count, position}: {zoom: number, count: number, position: {x: number, y: number}}) => (i: number) => {
  const [x, y] =
    [[cos, position.x], [sin, position.y]].map((arr) => {
      const [fn, offset] = arr as [(arg: number) => number, number]
      return fn(i * TAU / count) * 1 / zoom * OFFSET + offset
    })
  return {x, y}
}

// TODO: Skip if another node has the same coordinates
const createSpiral = ({axis, i, position: {x, y}}: {axis: number, i: number, position: {x: number, y: number}}) => ([cos, sin][axis]((i) / 11.7 * TAU) * (DISTANCE + ((i) * 5))) + [x, y][axis]

const useCxtmenu = (cy: Core | null) => {

  const nodes = useRecoilValue(nodesSelector)
  const existingIds = nodes.map(({id}) => id)

  const append = useAppend(nodesSelector)
  const remove = useRemoveObject(nodesSelector, {multiple: true})

  const client = useApolloClient()
  const positionsRef = useRef({})

  const includesLabel = useIncludes(labelsFilterSelector)
  const appendLabel = useAppend(labelsFilterSelector)

  const limit = parseInt(useSearchParam('limit') || '100000')

  useEffect(() => {
    const clearCxtMenu = () => {
      cy.nodes('.cxtmenu').remove()
      cy.nodes('.neighbor').remove()
    }


    const onRemove = async (node) => {
      const nodes = cy.nodes(':selected').add(node)
      const ids = nodes.map(node => node.id())
      remove('id', ids)
    }

    const onCollapse = async (node) => {

      const nodes = cy.nodes(':selected').add(node)
      const neighbors = nodes.map(node => node.neighborhood().map(node =>
        node.id())).flat()
      remove('id', neighbors)
    }

    const onTapCxtmenu = ({target: [node]}) => {
      const {option, targetNode} = node.data()
      actions[option]?.(targetNode)
    }

    const placeNeighbors = async (nodes) => {
      const ids = nodes.map(node => node.id())
      const {data: {GetNeighbors}} = await client.query({query: GET_NEIGHBORS, variables: {ids, limit}, context: {clientName: CLIENT.neo4j}})
      const neighborhood = JSON.parse(GetNeighbors)

      return neighborhood.map(([{properties: {id: originId}}, _neighbors]) => {
        const neighbors = _neighbors.filter(({properties: {id}}) => !existingIds.includes(id))

        const positions = neighbors.map(({labels, properties: {id: _id, title, name}}, i) => {
          const origin = cy.$id(originId)
          const position = origin.position()
          const [x, y] = [createSpiral({axis: 0, i, position}), createSpiral({axis: 1, i, position})]

          const id = _id + '-asNeighbor'
          const existing = cy.$id(id)
          if (existing.length > 0) {
            existing.style('opacity', 1)
            existing.position({x, y})
          } else {
            cy.add([{data: {id, label: title?.substring(0, 50) || name, originId, labels}, position: {x, y}, classes: 'neighbor ' + labels.join(' ')}])
          }
          return ({id, x, y})
        })

        positionsRef.current = positions.reduce((acc, {id, x, y}) => ({...acc, [id]: {x, y}}), positionsRef.current)

        return neighbors
      }).flat()
    }

    const addCxtmenu = async ({target: [node]}) => {
      clearCxtMenu()
      const neighbors = await placeNeighbors([node])

      const arr = Object.keys(actions)

      const zoom = cy.zoom()
      const size = 50 / zoom
      const fontSize = 20 / zoom
      const textOutlineWidth = 1.5 / zoom

      const nodePosition = node.position()
      const circle = getCircle({zoom: 1, position: nodePosition, count: arr.length})
      const options = arr.map((option, i) => ({
        data:
        {
          option, targetNode:
            node,
          count: neighbors.length
        }, classes:
          'cxtmenu ' + option,
        position: circle(i)
      }) as NodeDefinition)
      cy.add(options)

      cy.add([{
        data: {id: 'cxtmenu-background'},
        classes: 'cxtmenu-background cxtmenu',
        position: nodePosition
      }])
    }



    const onExpand = async (node) => {
      const selected = cy.nodes('[!isNavigator]:selected').add(node) as unknown as NodeSingular[];
      const neighbors = await placeNeighbors(selected)
      neighbors.length > 0 && append(neighbors.map(({properties: {id}}) => {
        const {x, y} = positionsRef.current[id + '-asNeighbor']
        return ({id, x, y})
      }))
      positionsRef.current = {}
    }

    const actions = {'expand': onExpand, 'remove': onRemove, 'collapse': onCollapse}

    const onMouseover = ({target: [node]}) => node.addClass('hover')
    const onMouseout = ({target: [node]}) => node.removeClass('hover')

    const onTap = clearCxtMenu

    const onTapNeighbor = ({target: [node]}) => {
      const id = node.id()
      const {x, y} = positionsRef.current[id]
      const {labels: [label]} = node.data()
      append({id: id.replace('-asNeighbor', ''), x, y})
      !includesLabel(label) && appendLabel(label)
    }

    const onCxttap = (props) => {
      addCxtmenu(props)
    }

    cy?.on('cxttap', 'node[isElement]', onCxttap)
    cy?.on('tap', onTap)
    cy?.on('tap', 'node.cxtmenu', onTapCxtmenu)
    cy?.on('mouseover', 'node.cxtmenu', onMouseover)
    cy?.on('mouseout', 'node.cxtmenu', onMouseout)
    cy?.on('tap', 'node.neighbor', onTapNeighbor)




    return () => {
      cy?.off('tap', onTap)
      cy?.off('cxttap', 'node[isElement]', onCxttap)
      cy?.off('tap', 'node.cxtmenu', onTapCxtmenu)
      cy?.off('mouseover', 'node.cxtmenu', onMouseover)
      cy?.off('mouseout', 'node.cxtmenu', onMouseout)
      cy?.off('tap', 'node.neighbor', onTapNeighbor)

    }
  }, [cy, existingIds]);
}

export default useCxtmenu
