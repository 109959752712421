const string = ``

export const injectEnvVars = () => {
  string.split('\n').forEach(s => {
    const [key, value] = s.split('=')
    if (value &&
      value !== 'null' &&
      key[0] !== '#' &&
      key.length > 0 &&
      !['APOLLO_KEY', 'SKIP_PREFLIGHT_CHECK'].includes(key)
    ) {
      process.env[key] = value
    }
  })
}

