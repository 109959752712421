import {IconButton, Tooltip} from '@material-ui/core'
import {ReactElement} from 'react'
import {useSetRecoilState} from 'recoil'
import UndoIcon from '../Icons/UndoIcon'
import {nodesHistory, useCanChangeHistory} from '../../atoms/nodesAtom'

const Undo = (): ReactElement => {

  const undo = useSetRecoilState(nodesHistory('undo'))
  const enabled = useCanChangeHistory('undo')
  const onClick = () => undo()

  return (
    <Tooltip enterDelay={500} title="Undo" aria-label="undo">
        <IconButton onClick={onClick} disabled={!enabled}>
        <UndoIcon />
      </IconButton>
    </Tooltip>
  )
}

export default Undo
