
import {gql, useApolloClient} from '@apollo/client';
import {useSetRecoilState} from 'recoil';
import {CLIENT} from '../../apollo';
import {articleIdAtom} from '../../atoms/articleIdAtom';


const GET_ARTICLE_ID = gql`
  query GET_ARTICLE_ID($id: String!) {
    Article(id:$id) {
      id
      article_id
    }
  }
`


export const useGetArticle = () => {
  const client = useApolloClient()
  const setArticleId = useSetRecoilState(articleIdAtom)

  return async (id: string, {updateState}: {updateState: boolean}) => {
    try {
      const result = await client.query({query: GET_ARTICLE_ID, variables: {id}, context: {clientName: CLIENT.neo4j}})
      const {data: {Article: [{article_id: articleId}]}} = result;
      updateState && setArticleId(articleId)
    } catch (error) {
      console.log(error)
    }
  }
}
