
import {selector} from 'recoil'
import SearchParams from '../helpers/searchParams'

import {atom} from 'recoil'

export const sessionIdAtom = atom<string | null>(
  {
    default: SearchParams().get('sessionId'),
    key: 'sessionIdAtom',
  },
)

export const sessionIdSelector = selector<string>({
  get: ({get}) => get(sessionIdAtom),
  key: 'sessionIdSelector',
  set: ({set}, id: string) => {
    const params = SearchParams()
    params.set('sessionId', id)
    window.location.assign(
      `?${params.toString()}`,
    )
    set(sessionIdAtom, id)
  },
})
