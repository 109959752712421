import {atom, selector} from 'recoil'

export type SearchHighlight = {searchString: string, color: string}
export const searchHighlightsAtom = atom<SearchHighlight[]>(
  {
    default: [],
    key: 'searchHighlightsAtom'
  }
)

export const toggleSearchHighlightSelector = selector({
  key: 'toggleSearchHighlight',
  get: ({get}) => get(searchHighlightsAtom),
  set: ({get, set}, _highlight) => {
    const highlight = _highlight as SearchHighlight
    const current = get(searchHighlightsAtom)
    const included = current.some(e => e.searchString === highlight.searchString)
    set(searchHighlightsAtom, included ? current.filter(({searchString}) => searchString !== highlight.searchString) : [...current, highlight])
  }
})
