import {IconButton, Tooltip} from '@material-ui/core'
import {ReactElement} from 'react'
import {useSetRecoilState} from 'recoil'
import {shouldLayoutAtom} from '../../atoms/shouldLayoutAtom'
import LayoutIcon from '../Icons/LayoutIcon'

const Layout = (): ReactElement => {

  const setShouldLayout = useSetRecoilState(shouldLayoutAtom)

  const onClick = () => setShouldLayout([true, {fit: true}])

  return (
    <Tooltip enterDelay={500} title="Layout" aria-label="layout">
      <IconButton onClick={onClick}>
        <LayoutIcon />
      </IconButton>
    </Tooltip>
  )
}

export default Layout
