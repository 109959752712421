import {Core} from 'cytoscape';
import {useEffect, useState} from 'react';
import {useDebounce} from 'react-use';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {nodesAtom, nodesSelector} from '../../atoms/nodesAtom';
import {shouldLayoutAtom} from '../../atoms/shouldLayoutAtom';
import {shouldSyncNodesAtom} from '../../atoms/shouldSyncNodesAtom';
import {layout} from './Graph';

const useSyncPositions = (cy: Core | null) => {

  const [updatedAt, setUpdatedAt] = useState(0);
  const [state, sync] = useRecoilState(nodesSelector)
  const setLocal = useSetRecoilState(nodesAtom)

  const [shouldSyncNodes, setShouldSyncNodes] = useRecoilState(shouldSyncNodesAtom)

  useDebounce(() => {
    if (updatedAt === 0) return
    const collection = cy.nodes('[isElement]')
    const nodes = collection.map((node) => {
      const position = node.position()
      return ({id: node.id(), ...position})
    })

    shouldSyncNodes
      &&
      state !== nodes
      ?
      sync(nodes) : setLocal(nodes)
    setShouldSyncNodes(true)
  }, 1000, [updatedAt])

  const [[shouldRun, {fit}], setShouldLayout] = useRecoilState(shouldLayoutAtom)


  useEffect(() => {
    const onPosition = (): void => {
      !shouldRun && setUpdatedAt(Date.now())
    }

    const runLayout = async ([shouldRun, {fit}]: [boolean, {fit: boolean}]) => {
      const navigator = cy.nodes('.navigator')
      await cy.remove(navigator)
      shouldRun && cy.layout({...layout}).run()
      //@ts-ignore 
      fit && cy.animate({fit})
      await cy.add(navigator)
      setShouldLayout([false, {fit: false}])
      cy.emit('viewport')
    }
    if (!cy) return

    runLayout([shouldRun, {fit}])


    cy.on('add free', 'node[isElement]', onPosition)
    return () => {
      cy?.off('add free', 'node[isElement]', onPosition)

    }
  }, [cy, shouldRun, fit]);
}

export default useSyncPositions
