import {AppBar} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import Amplify, {Auth} from 'aws-amplify';
import {createContext, useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useSearchParam} from 'react-use';
import {useRecoilValue} from 'recoil';
import {cognitoUserAtom} from './atoms/cognitoUserAtom';
import {loggedInAtom} from './atoms/loggedInAtom';
import {sessionIdSelector} from './atoms/sessionIdAtom';
import awsmobile from './aws-exports.js';
import GraphAppBar from './components/AppBar/GraphAppBar';
import Commands from './components/Commands/Commands';
import FeedbackDrawer from './components/Drawer/FeedbackDrawer';
import SearchDrawer from './components/Drawer/SearchDrawer';
import DisplayError from './components/Error/DisplayError';
import FilterDrawer from './components/Filter/Drawer';
import Graph from './components/Graph/Graph';
import Loading from './components/Loading/Loading';
import SearchNodes from './components/Search/SearchNodes';
import Sessions from './components/Session/Sessions';
import SessionsAppBar from './components/Session/SessionsAppBar';
import useGetSession from './components/Session/useGetSession';
import useGetUser from './components/User/useGetUser';
import {injectEnvVars} from './envVars';
import vscodeVariant from './helpers/vscodeVariant';
import Login from './Login/Login';
import themes from './theme';



Amplify.configure(awsmobile)
Auth.configure(awsmobile);

export const CyContext = createContext(null)

export const selectedTheme = 'light'
const App = () => {
  injectEnvVars()
  const cognitoUser = useRecoilValue(cognitoUserAtom);
  const loggedIn = useRecoilValue(loggedInAtom);

  const [href, setHref] = useState('');
  const _href = useSearchParam('href')

  useEffect(() => {
    !vscodeVariant && setHref(_href)
  }, [_href]);

  useEffect(() => {
    if (vscodeVariant) {
      const hrefDiv = document.getElementById("href")
      setHref(hrefDiv.innerHTML)
    }
  }, []);

  const {user, ...userProps} = useGetUser(cognitoUser?.id)

  const sessionId = useRecoilValue(sessionIdSelector)
  const sessionProps = useGetSession(sessionId)
  const error = userProps.error || sessionProps.error
  const networkStatus = userProps.networkStatus || sessionProps.networkStatus
  const retry = userProps.error ? userProps.refetch : sessionProps.refetch


  const theme = themes[selectedTheme]

  const cyRef = useRef(null)


  if (!loggedIn) return <Login />
  if (!user) return <AppBar />
  if (!sessionId) return <Sessions />
  if (error) return <DisplayError message={error.message} statusCode={networkStatus} retry={retry} />


  const getComponent = {
    graph: <Graph />, searchNodes: <SearchNodes />, cmd: <Commands />
  }

  if (!cognitoUser) return <p>Setting up user...</p>

  return (
    <CyContext.Provider value={cyRef}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <style>{`body {background-color: ${theme.palette.background.default}; margin: 0; padding: 0, width: 100vw; height: '100vh'}`}</style>
        </Helmet>
        {
          getComponent[href] ||
          <>
            <GraphAppBar />
            <SearchDrawer />
            <FeedbackDrawer />
            <FilterDrawer />
            <Graph />
          </>
        }
      </ThemeProvider>
    </CyContext.Provider>
  )
}

export default App
