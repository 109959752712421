import {Button, Drawer, IconButton, Theme, Toolbar, Tooltip, Typography} from '@material-ui/core'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import {makeStyles} from '@material-ui/styles'
import clsx from 'clsx'
import {ReactElement} from 'react'
import {useRecoilState} from 'recoil'
import {filterDrawerArticleExpandedAtom} from '../../atoms/filterDrawerArticleExpandedAtom'
import {filterDrawerExpandedAtom} from '../../atoms/filterDrawerExpandedAtom'
import Article from '../Article/Article'
import {drawerWidth} from '../Drawer/SearchDrawer'
import Spacer from '../Layout/Spacer'
import FilterList from './List'

const useStyles = makeStyles(({spacing}: Theme) => ({
  root: {display: "flex"},
  toolbar: {width: '100%', margin: 0, padding: 0},
  expanded: {width: drawerWidth},
  collapsed: {width: spacing(8)},
  header: {
    width: '100%',
    textAlign: 'center',
    paddingTop: spacing(1),
  },
  align: {opacity: 0},
  filter: {flex: '0 0 calc(100% - ${drawerWidth}px)'},
  article: {flex: 1},
  rotatedHeader: {
    transform: 'rotate(-90deg)', margin: 0, padding: 0, paddingBottom: spacing(1), marginTop: 'calc(50vh - 100px)',
  },
  button: {},
  showArticle: {}
}))

type Props = {}

const FilterDrawer = (props: Props): ReactElement => {
  const classes = useStyles({})
  const { } = props

  const [expanded, setExpanded] = useRecoilState(filterDrawerExpandedAtom)
  const toggle = () => setExpanded(state => !state)

  const [articleExpanded, setArticleExpanded] = useRecoilState(filterDrawerArticleExpandedAtom);

  return (
    <Drawer
      anchor='right'
      variant='permanent'
      open={true}>
      <div
        className={classes.root}
      >

        <div className={classes.filter}>
          <Toolbar className={classes.toolbar} />

          <Toolbar className={classes.toolbar}>
            {expanded ?
              <Button onClick={toggle} fullWidth>
                <Tooltip enterDelay={500} title="Collapse filter view" aria-label="collapse filter view">
                  <ChevronRight />
                </Tooltip>
                <div className={classes.header}>
                  <Typography variant='h6'>Filter</Typography>
                </div>
                <Tooltip enterDelay={500} title="Expand filter view" aria-label="expand filter view">
                  <ChevronRight className={classes.align} />
                </Tooltip>
              </Button> :
              <>
                <Spacer />
                <IconButton onClick={toggle}>
                  <ChevronLeft />
                </IconButton>
                <Spacer />
              </>
            }
          </Toolbar>
          <div className={expanded ? classes.expanded : classes.collapsed} >
            <FilterList expanded={expanded} />
          </div>
        </div>
        <div className={clsx(classes.article, !articleExpanded ? classes.collapsed : null)}>
          <Toolbar className={classes.toolbar} />
          <Toolbar className={classes.toolbar}>
            {articleExpanded ?
              <Button onClick={() => setArticleExpanded(c => !c)} fullWidth>
                <Tooltip enterDelay={500} title="Collapse article view" aria-label="collapse article view">

                  <ChevronRight />
                </Tooltip>
                <div className={classes.header}>
                  <Typography variant='h6'>Article</Typography>
                </div>
                <ChevronRight className={classes.align} />
              </Button> :
              <>
                <Spacer />
                <Tooltip enterDelay={500} title="Expand article view" aria-label="expand article view">
                  <IconButton onClick={() => setArticleExpanded(c => !c)}>
                    <ChevronLeft />
                  </IconButton>
                </Tooltip>
                <Spacer />
              </>
            }
          </Toolbar>
          {articleExpanded ? <Article /> :
            <Button className={(classes.rotatedHeader)} onClick={() => setArticleExpanded(c => !c)}>
              <Typography variant='h6' className={classes.showArticle}>Article</Typography>
            </Button>
          }
        </div>
      </div>
    </Drawer>
  )
}

export default FilterDrawer
