import {ApolloError, ApolloQueryResult, NetworkStatus, useApolloClient} from '@apollo/client'
import {useEffect, useState} from 'react'
import {useSetRecoilState} from 'recoil'
import {GET_SESSION} from '../../amplifyApi/session'
import {CLIENT} from '../../apollo'
import {labelsFilterAtom} from '../../atoms/labelsFilterAtom'
import {nodesLocalSelector} from '../../atoms/nodesAtom'
import {shouldSyncNodesAtom} from '../../atoms/shouldSyncNodesAtom'
import {typesFilterAtom} from '../../atoms/typesFilterAtom'

const useGetSession = (id?: string): {
  session: any;
  error: ApolloError;
  loading: boolean;
  networkStatus: NetworkStatus;
  refetch: (variables?: Partial<{
    id: string;
  }>) => Promise<ApolloQueryResult<any>>
} => {
  const [{data, error, networkStatus}, setResponse] = useState({data: null, error: null, networkStatus: null, })
  const [loading, setLoading] = useState(true);
  const session = data?.getSession

  const setNodes = useSetRecoilState(nodesLocalSelector)
  const setLabels = useSetRecoilState(labelsFilterAtom)
  const setTypes = useSetRecoilState(typesFilterAtom)
  const setShouldSyncNodes = useSetRecoilState(shouldSyncNodesAtom)

  const ApolloClient = useApolloClient()
  const fetch = async () => {
    setLoading(true)
    const response = await ApolloClient.query({query: GET_SESSION, variables: {id}, context: {clientName: CLIENT.amplify}})
    const {data} = response
    console.log("fetch ~ data", data)
    if (data) {
      const {
        nodes,
        labels,
        types,
      } = data.getSession
      setShouldSyncNodes(false)
      setNodes(nodes.map(({__typename, ...props}) => props))
      setLabels(labels.filter((label) => !['AUTHORED', 'MENTIONS', 'Author'].includes(label)))
      setTypes(types)
      setLoading(false)
    }

    // @ts-ignore
    setResponse(response)
    return response
  }
  useEffect(() => {
    id && fetch()
  }, [id]);

  const refetch = fetch


  return {session, error, loading: loading || !session, networkStatus, refetch}
}

export default useGetSession
